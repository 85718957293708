/**
 * Created by mac on 12/22/20
 */

var Upgradable = function (unit, options) {
    UnitComponent.call(this, unit);

    var data = unit.getData().upgradable;
    this.level = options.level !== undefined ? options.level : data.level;
    this.progress = options.progress !== undefined ? options.progress : 0;
    this.stagesByLevel = data.stagesByLevel || 0;
    this.stages = this.stagesByLevel * this.level + data.stages;
    this.collectedStage = data.collectedStage;
    this.moveToNextStage = data.moveToNextStage;

    if (cleverapps.gameModes.upgradeInTwoTurns) {
        this.progress = this.stages - 2;
    }

    if (this.progress >= this.stages) {
        setTimeout(this.moveToNextLevel.bind(this), 0);
    }
};

Upgradable.prototype = Object.create(UnitComponent.prototype);
Upgradable.prototype.constructor = Upgradable;

Upgradable.prototype.onUpdateLevel = function (silent) {
    if (this.view) {
        this.view.onUpdateLevel(silent);
    } 
};

Upgradable.prototype.onStartUpdateLevel = function () {
    if (this.view) {
        this.view.onStartUpdateLevel();
    } 
};

Upgradable.prototype.onChangeProgress = function (silent) {
    if (this.view) {
        this.view.onChangeProgress(silent);
    } 
};

Upgradable.prototype.onBuild = function () {
    if (this.view) {
        this.view.onBuild();
    } 
};

Upgradable.prototype.onBeforeChangeUnit = function () {
    if (this.view) {
        this.view.onBeforeChangeUnit();
    } 
};

Upgradable.prototype.save = function (data) {
    data.progress = this.progress;
    data.level = this.level;
};

Upgradable.prototype.canCollect = function (unit, options) {
    var isBuilt = options && options.skipBuild || this.unit.isBuilt();
    return unit.code === this.unit.code && unit.stage === this.collectedStage && this.progress < this.stages
        && unit.isBuilt() && isBuilt;
};

Upgradable.prototype.buildingComplete = function () {
    this.buildingCompleteTimeout = setTimeout(function () {
        delete this.buildingCompleteTimeout;
        this.onBuild();
    }.bind(this), 1500);
};

Upgradable.prototype.destructor = function () {
    if (this.buildingCompleteTimeout !== undefined) {
        clearTimeout(this.buildingCompleteTimeout);
        delete this.buildingCompleteTimeout;
    }
    if (this.moveToNextLevelTimeout !== undefined) {
        clearTimeout(this.moveToNextLevelTimeout);
        delete this.moveToNextLevelTimeout;
    }
    if (this.incProgressTimeout !== undefined) {
        clearTimeout(this.incProgressTimeout);
        delete this.incProgressTimeout;
    }
};

Upgradable.prototype.moveToNextLevel = function () {
    if (this.moveToNextStage) {
        this.onBeforeChangeUnit();
        var newUnit = new Unit({
            code: this.unit.code,
            stage: this.unit.stage + 1,
            unbuilt: true
        });
        this.unit.remove();
        newUnit.setPosition(this.unit.x, this.unit.y);
        Map2d.currentMap.add(Map2d.LAYER_UNITS, newUnit.x, newUnit.y, newUnit);
        Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);
        Map2d.currentMap.onUnitAvailable(newUnit);

        if (cleverapps.gameModes.skipBuildingStage) {
            var buildable = newUnit.findComponent(Buildable);
            buildable.onUpdateState();

            setTimeout(function () {
                newUnit.completeBuilding();
            }, 200);
        }
    } else {
        this.level++;
        this.stages = this.stagesByLevel * this.level + this.unit.getData().upgradable.stages;
        this.progress = 0;
        this.onUpdateLevel();
        this.onChangeProgress(true);
        this.unit.save();
    }
};

Upgradable.prototype.handleRemove = function () {
    Game.currentGame.updatePulsing();
};

Upgradable.prototype.incProgress = function (delay) {
    this.progress++;
    this.unit.save();

    this.incProgressTimeout = setTimeout(function (progress) {
        delete this.incProgressTimeout;
        this.onChangeProgress();
        if (progress === this.stages) {
            this.onStartUpdateLevel();
            this.moveToNextLevelTimeout = setTimeout(function () {
                delete this.moveToNextLevelTimeout;
                this.moveToNextLevel();
            }.bind(this), 1000);
        }
    }.bind(this, this.progress), delay);
};

Upgradable.prototype.handleClick = function () {
    var family = this.unit.getFamily();

    if (family.type === "resource" && this.unit.stage === family.units.length - 2 && !cleverapps.meta.isFocused()) {
        this.unit.squeeze();
        cleverapps.meta.display({
            focus: "CastleWindow",
            action: function (f) {
                this.unit.playClickSound();

                new GuideWindow(CastleGuideOptions, {
                    code: this.unit.code,
                    stage: family.units.length - 1
                });
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        });
        return true;
    }
};

Upgradable.prototype.canAcceptDraggable = function (unit, options) {
    return this.canCollect(unit, options);
};

Upgradable.prototype.acceptDraggable = function (unit) {
    var collectible = unit.findComponent(ResourceCollectible);
    collectible.collect(this.unit);
};