/**
 * Created by andrey on 11.06.2024
 */

var VirtualResource = function (bundleName, key) {
    this.bundleName = bundleName;
    this.key = key;
};

cc.VirtualResource = VirtualResource;

VirtualResource.prototype.onUnload = function () {
};

VirtualResource.prototype.toString = function () {
    return this.bundleName + "[" + this.key + "]";
};

VirtualResource.prototype.getInjectTo = function (preferredBundles) {
    var bundle = bundles[this.bundleName];
    if (!bundle.virtual) {
        return [this.bundleName];
    }

    var injectToSet = bundle.injectToSet;

    if (cc.rendererBundle && injectToSet[cc.rendererBundle] && bundles[cc.rendererBundle].loaded) {
        return [cc.rendererBundle];
    }

    if (preferredBundles && preferredBundles.length > 0) {
        preferredBundles = preferredBundles.filter(function (name) {
            var bundle = bundles[name];
            if (cleverapps.config.debugMode && bundle && bundle.virtual) {
                throw "Virtual bundle " + name + " shouldn't be preferred";
            }

            return injectToSet[name] && bundle.loaded;
        });

        if (preferredBundles.length > 0) {
            return preferredBundles;
        }
    }

    return bundle.injectTo;
};

VirtualResource.prototype.resolve = function (preferredBundles) {
    var bundle = bundles[this.bundleName];
    if (!bundle.virtual) {
        return this;
    }

    var collection = "frames";
    if (this instanceof VirtualJson) {
        collection = "jsons";
    } else if (this instanceof VirtualUrl) {
        collection = "urls";
    }

    var injectTo = this.getInjectTo(preferredBundles);
    var loaded = false;
    for (var i in injectTo) {
        var bundleName = injectTo[i];
        if (cleverapps.bundleLoader.isLoaded(bundleName)) {
            var resource = bundles[bundleName][collection][this.key];
            if (resource) {
                return resource;
            }
            loaded = true;
        }
    }

    if (!loaded) {
        var message = this.toString() + " not loaded  - " + injectTo.join(";");
        if (cleverapps.config.debugMode) {
            throw new Error(message);
        } else {
            cleverapps.throwAsync(message);
        }
    }
};

VirtualResource.prototype.originalPath = function () {
    var bundle = bundles[this.bundleName];
    return bundle.originalPaths && bundle.originalPaths[this.key];
};

VirtualResource.prototype.isLazy = function () {
    var meta = bundles[this.bundleName].meta;
    return meta && meta.lazy && meta.lazy[this.key];
};

VirtualResource.prototype.getBundleName = function () {
    return this.bundleName;
};

VirtualResource.prototype.isLoaded = function () {
    return bundles[this.bundleName].isLoaded();
};

VirtualResource.prototype.getDimensions = function () {
    var meta = bundles[this.bundleName].meta;
    var size = meta && meta.sizes && meta.sizes[this.key];
    if (size) {
        return cc.size(size.width * resolutionScale, size.height * resolutionScale);
    }
};

VirtualResource.prototype.getAnchors = function () {
    var meta = bundles[this.bundleName].meta;
    return meta && meta.anchors && meta.anchors[this.key];
};

VirtualResource.prototype.save = function () {
    
};
