/**
 * Created by iamso on 16.11.2021
 */

var LockedComponent = function (unit, options) {
    UnitComponent.call(this, unit);

    this.harvestDuration = this.calcLockTime();

    this.load(options);
};

LockedComponent.prototype = Object.create(UnitComponent.prototype);
LockedComponent.prototype.constructor = LockedComponent;

LockedComponent.prototype.onUnlock = function (callback) {
    if (this.view) {
        var duration = this.view.getUnlockAnimationDuration();
        this.view.unlock();
        this.unlockTimeout = setTimeout(callback, duration * 1000);
    } else {
        callback();
    }
};

LockedComponent.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

LockedComponent.prototype.calcLockTime = function () {
    var type = this.unit.getType();
    var data = this.unit.getData();

    if (data.lockable.duration) {
        return cleverapps.parseInterval(data.lockable.duration);
    }

    if (data.resourceChest) {
        return LockedComponent.LOCKTIME.resourceChest[this.unit.stage];
    }

    if (LockedComponent.LOCKTIME[type]) {
        if (cleverapps.config.debugMode) {
            return cleverapps.parseInterval("30 seconds") * (this.unit.stage + 1);
        }
        return LockedComponent.LOCKTIME[type][this.unit.stage];
    }

    return LockedComponent.LOCKTIME.default;
};

LockedComponent.prototype.startUnlocking = function () {
    var timeLeft = this.getTimeLeft();

    if (timeLeft > 0) {
        this.lockTimeout = new cleverapps.LongTimeout(this.unlock.bind(this), timeLeft);
        this.preparePush(timeLeft);
        this.onUpdateState();
        this.informComponents();
    } else {
        this.unlock();
    }
};

LockedComponent.prototype.lock = function () {
    this.onUpdateState();
    this.informComponents();
};

LockedComponent.prototype.unlock = function () {
    this.remove();
    this.onUnlock(this.informComponents.bind(this));
    this.onUpdateState();
};

LockedComponent.prototype.remove = function () {
    delete this.lockTime;
    this.destructor();
    this.unit.save();
};

LockedComponent.prototype.informComponents = function () {
    this.unit.components.forEach(function (component) {
        component.lockStateUpdate && component.lockStateUpdate(this.isLockActive());
    }, this);
};

LockedComponent.prototype.getTimeLeft = function () {
    if (!this.isUnlockInProcess()) {
        return this.harvestDuration;
    }

    var timePassed = Date.now() - this.lockTime;
    return Math.max(0, this.harvestDuration - timePassed);
};

LockedComponent.prototype.handleClick = function () {
    if (!this.isLockActive()) {
        return false;
    }

    if (this.isUnlockInProcess()) {
        cleverapps.meta.display({
            focus: "ConfirmSpeedUpWindowLock",
            action: function (f) {
                new ConfirmSpeedUpWindow({
                    timeLeft: this.getTimeLeft(),
                    totalDuration: this.harvestDuration,
                    eventName: cleverapps.EVENTS.SPENT.UNLOCK,
                    callback: this.unlock.bind(this),
                    source: this.unit.onGetView()
                });
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        });
    }

    return true;
};

LockedComponent.prototype.getActionInfo = function () {
    if (!this.isLockActive() || this.isUnlockInProcess()) {
        return undefined;
    }

    return {
        title: { image: bundles.merge.frames.unit_lock_png, title: Messages.get("Locked") },
        duration: this.getTimeLeft(),
        buttons: {
            unlock: {
                lock: this,
                duration: this.getTimeLeft(),
                action: function () {
                    this.lockTime = Date.now();
                    this.startUnlocking();
                    this.unit.save();
                }.bind(this)
            }
        }
    };
};

LockedComponent.prototype.destructor = function () {
    clearTimeout(this.lockTimeout);
    clearTimeout(this.unlockTimeout);
};

LockedComponent.prototype.load = function (data) {
    if (data.lockTime === 0 || data.lock) {
        if (this.harvestDuration) {
            this.lockTime = 0;
            this.lock();
        }
    } else if (data.lockTime > 0) {
        this.lockTime = data.lockTime;
        this.startUnlocking();
    }
};

LockedComponent.prototype.isLockActive = function () {
    return this.lockTime !== undefined;
};

LockedComponent.prototype.isUnlockInProcess = function () {
    return this.lockTime > 0;
};

LockedComponent.prototype.save = function (data) {
    if (this.isLockActive()) {
        data.lockTime = this.lockTime;
    }
};

LockedComponent.prototype.setLockActive = function (active) {
    if (active && !this.isLockActive()) {
        this.lockTime = 0;
        this.lock();
        this.unit.save();
    } else if (!active && this.isLockActive()) {
        this.unlock();
    }
};

LockedComponent.prototype.preparePush = function (timeLeft) {
    cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.UNITLOCK_OPEN, timeLeft);
};

LockedComponent.LOCKTIME = {
    default: cleverapps.parseInterval(cleverapps.config.debugMode ? "1 minutes" : "1 hours"),
    chest: ["10 minutes", "1 hour", "24 hours"].map(function (str) {
        return cleverapps.parseInterval(str); 
    }),
    resourceChest: ["0 minutes", "45 seconds", "5 minutes"].map(function (str) {
        return cleverapps.parseInterval(str); 
    })
};
