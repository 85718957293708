/**
 * Created by spepa on 04.07.2024
 */

var LazyAsset = cc.Node.extend({
    ctor: function (res, options) {
        options = options || {};
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.virtualResource = res;
        this.asset = undefined;
        this.assetSize = res.getDimensions();
        if (!this.assetSize) {
            cleverapps.throwAsync("Empty dimensions for lazy asset " + res);
        }

        this.options = options;
        this._pendingCalls = [];
        this.bundle = this.virtualResource.getBundleName();

        this.createPreview();

        cleverapps.lazyAssets.on("onLoaded", this.onLoaded.bind(this), this);
        cleverapps.lazyAssets.on("onAbort", function () {
            this.preview.stopAllActions();
            this.asset && this.asset.stopAllActions();
        }.bind(this), this);
    },

    onAssetLoad: function (callback) {
        if (this.asset) {
            callback();
        } else {
            this._pendingCalls.push(callback);
        }
    },

    isSpine: function () {
        return this.virtualResource instanceof VirtualJson;
    },

    createPreview: function () {
        this.preview = this.options.preview || new cc.Sprite(bundles.merge.frames.unit_unknown);
        this.addChild(this.preview);
        this.adaptToImage(this.preview);

        this.preview.setVisible(false);
        this.preview.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.Show()
        ));
    },

    adaptToImage: function (image) {
        this.setContentSize2(this.assetSize || image.getContentSize());
        image.setPositionRound(this.width / 2, this.height / 2);
        this.setCascadeOpacityEnabled(true);
        this.setCascadeColorEnabled(true);
    },

    createAsset: function () {
        if (this.virtualResource instanceof VirtualFrame) {
            this.asset = new cc.Sprite(this.virtualResource);
        } else {
            this.asset = new cleverapps.Spine(this.virtualResource);
        }
        this.addChild(this.asset);

        if (this.preview.isDisplayed()) {
            var duration = 0.5;
            cleverapps.UI.animateUpgrade(this.preview, this.asset, {
                parent: Map2d.currentMap.getView().animations,
                duration: duration,
                callback: function () {
                    this.adaptToImage(this.asset);
                    this._callPending();
                }.bind(this)
            });
        } else {
            this.preview.removeFromParent(true);
            this.adaptToImage(this.asset);
            this._callPending();
        }
    },

    onLoaded: function (loadedName, code) {
        if (loadedName !== this.bundle) {
            return;
        }
        this._loading = false;
        if (code === cleverapps.CODE_SUCCEED && !this.asset) {
            this.createAsset();
        }
    },

    startLoading: function () {
        var now = Date.now();
        if (this.asset || this._loading || (this._loadStart && this._loadStart + LazyAsset.TRY_LOAD_TIMEOUT > now)) {
            return;
        }
        this._loading = true;
        this._loadStart = now;
        cleverapps.lazyAssets.loadBundle(this.bundle);
    },

    _callPending: function () {
        var running = true;
        this.addCleaner(function () {
            running = false;
        });

        for (var i = 0; i < this._pendingCalls.length; i++) {
            if (!running) {
                break;
            }
            var call = this._pendingCalls[i];
            if (typeof call === "function") {
                call();
            } else {
                this.asset[call.method] && this.asset[call.method].apply(this.asset, call.args);
            }
        }
    },

    getAnimationData: function () {
        return this.asset && this.asset.getAnimationData ? this.asset.getAnimationData.apply(this.asset, arguments) : undefined;
    },

    getAnimationDuration: function () {
        return this.asset && this.asset.getAnimationDuration ? this.asset.getAnimationDuration.apply(this.asset, arguments) : 0;
    },

    getTimeLeft: function () {
        return this.asset && this.asset.getTimeLeft ? this.asset.getTimeLeft.apply(this.asset, arguments) : 0;
    },

    isSaveToRemove: function () {
        return this.asset && this.asset.isSaveToRemove ? this.asset.isSaveToRemove.apply(this.asset, arguments) : true;
    },

    getTimeScale: function () {
        return this.asset && this.asset.getTimeScale ? this.asset.getTimeScale.apply(this.asset, arguments) : 1;
    },

    hasAnimation: function () {
        if (!this.isSpine()) {
            return false;
        }
        if (!this.asset) {
            cleverapps.throwAsync("using hasAnimation while resource not loaded " + this.virtualResource);
            return false;
        }
        return this.asset.hasAnimation.apply(this.asset, arguments);
    },

    setCompleteListener: function (callback) {
        if (this.asset && this.asset.setCompleteListener) {
            this.asset.setCompleteListener(callback);
        } else {
            callback && callback();
        }
    },

    setCompleteListenerRemove: function (callback) {
        if (this.asset && this.asset.setCompleteListenerRemove) {
            this.asset.setCompleteListenerRemove(callback);
        } else {
            callback && callback();
            this.removeFromParent();
        }
    },

    setCompleteListenerOnce: function (callback) {
        if (this.asset && this.asset.setCompleteListenerOnce) {
            this.asset.setCompleteListenerOnce(callback);
        } else {
            callback && callback();
        }
    },

    _processMethodCall: function (method, args) {
        if (this.asset) {
            this.asset[method] && this.asset[method].apply(this.asset, args);
        } else {
            this._pendingCalls.push({ method: method, args: args });
        }
    },

    setIdle: function () {
        this._processMethodCall("setIdle", arguments);
    },

    setIdleSet: function () {
        this._processMethodCall("setIdleSet", arguments);
    },

    setAnimation: function () {
        this._processMethodCall("setAnimation", arguments);
    },

    addAnimation: function () {
        this._processMethodCall("addAnimation", arguments);
    },

    setTimeScale: function () {
        this._processMethodCall("setTimeScale", arguments);
    },

    clearTrack: function () {
        this._processMethodCall("clearTrack", arguments);
    },

    setSkin: function () {
        this._processMethodCall("setSkin", arguments);
    },

    setAnimationAndIdleAfter: function () {
        this._processMethodCall("setAnimationAndIdleAfter", arguments);
    },

    setSafeToRemove: function () {
        this._processMethodCall("setSafeToRemove", arguments);
    }

});

LazyAsset.IsAssetLoaded = function (asset) {
    if (asset instanceof cleverapps.Spine) {
        return true;
    }
    return asset instanceof LazyAsset && Boolean(asset.asset);
};

LazyAsset.TRY_LOAD_TIMEOUT = cleverapps.parseInterval("20 seconds");