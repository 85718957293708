/**
 * Created by mac on 12/23/20
 */

var MonsterView = function (component, unitView) {
    this.unitView = unitView;
};

MonsterView.prototype.onDie = function () {
    this.unitView.sprite.setAnimation(0, "die", false);
    this.unitView.sprite.setCompleteListener(function () {
        this.unitView.removeFromParent();
    }.bind(this));
    cleverapps.audio.playSound(bundles.monster.urls.die_effect);
};

MonsterView.prototype.onSpawn = function (delay) {
    this.unitView.hideAdditionalViews();
    this.unitView.sprite.stopAllActions();
    this.unitView.sprite.setVisible(false);

    this.unitView.sprite.runAction(new cc.Sequence(
        new cc.DelayTime(delay || 0),
        new cc.Show(),
        new cc.CallFunc(function () {
            this.unitView.sprite.setAnimationAndIdleAfter("spawn", "idle");
            if (this.unitView.unit.stage === 2) {
                cleverapps.audio.playSound(bundles.monster.urls.head_spawn_effect);
            } else {
                cleverapps.audio.playSound(bundles.monster.urls.tentacles_spawn_effect);
            }
        }.bind(this))
    ));
};

MonsterView.prototype.onBattle = function () {
    if (this.unitView.sprite.hasAnimation("battle")) {
        this.unitView.sprite.setAnimationAndIdleAfter("battle", "idle");
    }
    cleverapps.audio.playSound(bundles.monster.urls["push_effect_" + this.unitView.unit.stage]);
};

MonsterView.prototype.getBattleAnimationDuration = function () {
    var animation = this.unitView.sprite.getAnimationData("battle");

    return animation && animation.duration || 0;
};