/**
 * Created by evgeny on 18.06.2024
 */

var SwitchableView = cc.Node.extend({
    ctor: function (switchable, unitView) {
        this._super();
        this.switchable = switchable;
        this.unitView = unitView;

        this.showChoice(this.switchable.choice);
    },

    animateChoice: function (choice, f) {
        f = f || function () {};

        if (this.unitView.sprite && this.unitView.sprite.hasAnimation("switch" + choice)) {
            this.unitView.sprite.setAnimationAndIdleAfter("switch" + choice, "choice" + choice);
            this.unitView.sprite.setCompleteListenerOnce(f);
        } else {
            var duration = 1;
            cleverapps.UI.animateUpgrade(this.unitView, this.unitView, {
                parent: Map2d.currentMap.getView().animations,
                duration: duration,
                cloud: true,
                callback: f
            });
        }
    },

    animateHover: function () {
        this.hoverAnimation = this.unitView.sprite.spine.runAction(new cc.RepeatForever(
            new cc.Spawn(
                new cc.Sequence(
                    new cc.MoveBy(1, 0, 5).easing(cc.easeInOut(2)),
                    new cc.MoveBy(1, 0, -5).easing(cc.easeInOut(2))
                ),
                AnimationsLibrary.blinkShader()
            )
        ));
    },

    stopAnimateHover: function () {
        if (this.hoverAnimation) {
            this.unitView.sprite.spine.stopAction(this.hoverAnimation);
            delete this.hoverAnimation;
        }
    },

    showChoice: function (choice) {
        this.unitView.sprite.setAnimation(0, "choice" + choice, true);
    }
});