/**
 * Created by olga on 27.09.2024
 */

var FixableView = cc.Node.extend({
    ctor: function (component, unitView) {
        this._super();

        this.unitView = unitView;
    },

    getCustomUpgradeAnimation: function () {
        if (this.unitView.sprite && this.unitView.sprite.hasAnimation("switch0")) {
            return "switch0";
        }
    },

    afterUpgradeStage: function (oldUnitImage, f) {
        var customAnimation = this.getCustomUpgradeAnimation();
        if (customAnimation) {
            oldUnitImage.removeFromParent();
            this.unitView.sprite.setAnimation(0, customAnimation, false);
            this.unitView.sprite.setCompleteListenerOnce(f);
        } else {
            this.unitView.setVisible(false);
            var duration = 1;
            cleverapps.UI.animateUpgrade(oldUnitImage, this.unitView, {
                duration: duration,
                cloud: true,
                parent: Map2d.currentMap.getView().animations,
                callback: f
            });
        }
    },

    upgrade: function (newUnitView, f) {
        newUnitView.setVisible(false);
        var duration = 1;
        cleverapps.UI.animateUpgrade(this.unitView, newUnitView, {
            duration: duration,
            cloud: true,
            parent: Map2d.currentMap.getView().animations,
            callback: f
        });
    }

});