/**
 * Created by Aleksandr on 02.08.2024
 */

var OpenLocationComponent = function (unit, options, openLocation) {
    UnitComponent.call(this, unit);
    cleverapps.home.setLocationAvailable(openLocation);
};

OpenLocationComponent.prototype = Object.create(UnitComponent.prototype);
OpenLocationComponent.prototype.constructor = OpenLocationComponent;
