/**
 * Created by mac on 12/15/20
 */

var FogBlockView = cc.Node.extend({
    ctor: function (fogBlock) {
        this._super();
        this.fogBlock = fogBlock;

        this.setCascadeColorEnabled(true);
        this.setCascadeOpacityEnabled(true);

        this.setAnchorPoint(0.5, 0.5);

        fogBlock.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        fogBlock.on("open", this.createListener(this.onOpen.bind(this)), this);
        fogBlock.on("updateState", this.createListener(this.onUpdateState.bind(this)), this);
        fogBlock.on("updateBlocker", this.createListener(this.onUpdateBlocker.bind(this)), this);
        fogBlock.on("wandsChanged", this.createListener(this.onWandsChanged.bind(this)), this);
    },

    hideBlocker: function () {
        if (this.blocker) {
            cleverapps.audio.playSound(bundles.fog.urls.fog_unlock_effect);

            this.blocker.blockers.forEach(function (blocker) {
                blocker.lock.replaceParentSamePlace(this);
                blocker.lock.setLocalZOrder(-1);
                blocker.lock.setAnimation(0, "open", false);
                blocker.lock.setCompleteListenerRemove();
            }.bind(this));

            this.blocker.runAction(new cc.Sequence(
                new cc.Spawn(
                    new cc.JumpBy(0.2, 30, 0, 20, 1),
                    new cc.RotateBy(0.2, 80).easing(cc.easeIn(1))
                ),
                new cc.RemoveSelf()
            ));
            this.blocker = undefined;
        }
    },

    createBlocker: function (conditions, silent) {
        var styles = cleverapps.skins.getSlot("fogBlocker") || cleverapps.styles.FogBlockView.blocker;
        var blockers = [], frame;

        for (var i = 0; i < conditions.length; i++) {
            if (conditions[i].abtest) {
                continue;
            }
            var lock = new cleverapps.Spine(bundles.fog.jsons.fog_blocker_json);
            lock.setAnimation(0, "idle", true);
            lock.setPositionRound(styles.lock);

            var blocker = new cc.Node();
            blocker.setContentSize2(styles);
            blocker.addChild(lock);
            blocker.lock = lock;

            blockers.push(blocker);
            blocker.setCascadeColorEnabled(true);
            blocker.setCascadeOpacityEnabled(true);

            frame = conditions[i].hero && bundles.unit_icons.frames["small_icon_" + conditions[i].hero];
            if (frame) {
                var heroIcon = new cc.Sprite(frame);
                heroIcon.setPositionRound(styles.icon);
                blocker.addChild(heroIcon);
            }

            if (conditions[i].level) {
                var levelText = cleverapps.UI.generateImageText(conditions[i].level, cleverapps.styles.FONTS.FOG_BLOCKER_TEXT);
                levelText.setPositionRound(styles.text.x, styles.text.y);
                levelText.fitTo(styles.content.width, styles.content.height);
                blocker.addChild(levelText);
            }

            frame = conditions[i].familyLastUnit && cleverapps.skins.getSlot("unitIcon", conditions[i].familyLastUnit);
            if (frame) {
                var castle = new cc.Sprite(frame);
                castle.setPositionRound(styles.icon);
                blocker.addChild(castle);
            }

            frame = conditions[i].unit && cleverapps.skins.getSlot("unitIcon", conditions[i].unit);
            if (frame) {
                var unit = new cc.Sprite(frame);
                unit.setPositionRound(styles.icon);
                blocker.addChild(unit);
            }

            cleverapps.UI.onClick(blocker, this.fogBlock.displayCondition.bind(this.fogBlock, conditions[i]));
        }

        if (this.blocker) {
            this.blocker.removeFromParent();
        }

        this.blocker = new cleverapps.Layout(blockers, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.blocker.blockers = blockers;

        this.blocker.setCascadeColorEnabled(true);
        this.blocker.setCascadeOpacityEnabled(true);

        this.addChild(this.blocker);
        this.blocker.setPositionRound(styles.position);
        this.blocker.setAnchorPoint(0.5, 0);
        if (!silent) {
            this.blocker.setScale(0);
            this.blocker.runAction(new cc.ScaleTo(0.3, 1));
            cleverapps.audio.playSound(bundles.fog.urls.fog_lock_effect);
        }
    },

    addBlocker: function (state, silent) {
        if (cleverapps.gameModes.fogBlockerDisabled) {
            return;
        }

        if (state === FogBlock.NOTREADY) {
            this.createBlocker(this.fogBlock.blockerConditions || [], silent);
        } else {
            this.hideBlocker();
        }
    },

    createBar: function () {
        var styles = cleverapps.styles.FogBlockView.opener;

        var text = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.WAND_PROGRESS_TEXT);
        text.setAnchorPoint(0.5, 0.5);
        text.setPositionRound(styles.text);
        text.setLocalZOrder(1);

        var bar = new ScaledProgressBar({
            background: bundles.progress_bar.frames.bg_fog,
            progress: bundles.progress_bar.frames.bar_fog_blue,
            progressFrames: {
                0: bundles.progress_bar.frames.bar_fog_blue,
                100: bundles.progress_bar.frames.bar_fog_green || bundles.progress_bar.frames.bar_fog_blue
            },

            onChange: function (silent) {
                var needed = this.fogBlock.getOpenPrice();
                var amount = Math.min(Game.currentGame.wands, needed);

                text.setString(amount + " / " + needed);
                text.fitTo(styles.text.width);

                text.stopAllActions();
                if (silent) {
                    text.setScale(1);
                } else {
                    text.runAction(new cc.Sequence(
                        new cc.ScaleTo(0.15, 1.4),
                        new cc.ScaleTo(0.15, 1)
                    ));
                }
            }.bind(this)
        });
        bar.setLength(styles.width);
        bar.setPositionRound(styles);

        var icon = this.icon = new cc.Sprite(bundles.reward_icons_merge.frames.wand_icon);
        icon.setPositionRound(styles.icon);
        icon.setLocalZOrder(1);
        bar.addChild(icon);

        bar.addChild(text);

        return bar;
    },

    createOpenerWindow: function () {
        var styles = cleverapps.styles.FogBlockView.openerWindow;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setPositionRound(styles);

        container.setPositionRound(cleverapps.styles.FogBlockView.opener);
        container.setContentSize2(cleverapps.styles.FogBlockView.opener);

        if (!this.fogBlock.isFree()) {
            var opener = this.createBar();
            opener.setPositionRound({ align: "center" }, { align: "center" });
            opener.setPercentage(100);
            container.addChild(opener);

            var blick = new cleverapps.Spine(bundles.fog.jsons.fog_progress_blick);
            blick.setPositionRound(cleverapps.styles.FogBlockView.opener.blick);
            blick.setAnimation(0, "animation", false);
            blick.setCompleteListener(function () {
                setTimeout(blick.setAnimation.bind(blick, 0, "animation", false), cleverapps.Random.random(500, 1000));
            });
            opener.addChild(blick);
        }

        var openButton = container.button = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "FogBlock.attack",
            onClicked: this.handleOpen.bind(this),
            type: styles.button.type || cleverapps.styles.UI.Button.Images.small_button_blue
        });
        openButton.adviceTarget = "openfog";
        openButton.setPositionRound(this.fogBlock.isFree() ? styles.button.free : styles.button);
        container.addChild(openButton);

        return container;
    },

    hideOpener: function () {
        if (this.bar) {
            this.bar.runAction(new cc.Sequence(
                new cc.ScaleTo(0.2, 0),
                new cc.RemoveSelf()
            ));
            this.bar = undefined;
        }
    },

    hideOpenerWindow: function (delay) {
        if (!this.openerWindow) {
            return 0;
        }

        this.openerWindow.runAction(new cc.Sequence(
            new cc.DelayTime(delay || 0),
            new cc.ScaleTo(0.2, 0),
            new cc.RemoveSelf()
        ));
        this.openerWindow = undefined;
    },

    collectBubbles: function () {
        var delay = 0;

        if (!this.bubbles || !this.bubbles.length) {
            return delay;
        }

        delay = 0.5;

        this.bubbles.forEach(function (bubbleView) {
            delay += 0.02;

            bubbleView.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                AnimationsLibrary.animateCollect(bubbleView, "mainWorld", {
                    collectEffect: true,
                    sound: bundles.main.urls.coins_drop_effect
                }),
                new cc.CallFunc(function () {
                    cleverapps.toolbar.resetByType(ToolbarItem.TRAVEL_BOOK);
                }),
                new cc.RemoveSelf()
            ));
        });
        this.bubbles = undefined;

        return delay;
    },

    onWandsChanged: function (silent) {
        if (this.bar && !this.fogBlock.isMoney()) {
            var wands = this.fogBlock.fogs.wands;
            var needed = this.fogBlock.getOpenPrice();
            var amount = Math.min(wands, needed);

            this.bar.setGoal(needed, silent);
            this.bar.setPercentage(amount, {
                time: 0,
                animated: !silent
            });
        }
    },

    addMoneyOpennerWindow: function (silent) {
        var styles = cleverapps.styles.FogBlockView.openerWindow.money;

        this.openerWindow = new cc.Node();
        this.openerWindow.setAnchorPoint(0.5, 0.5);
        this.openerWindow.setPositionRound(styles);
        this.addChild(this.openerWindow);

        var sellerIcon = new cc.Sprite(bundles.merge.frames.seller_icon);
        sellerIcon.setPositionRound(styles.sellerIcon);
        sellerIcon.fogBlockId = this.fogBlock.id;

        cleverapps.UI.onClick(sellerIcon, this.handleBuy.bind(this));
        cleverapps.UI.applyHover(sellerIcon);
        this.openerWindow.addChild(sellerIcon);

        var bubbles = this.bubbles = this.fogBlock.getBubbles().map(function (bubble) {
            var bubbleView = new PocketBubbleView(bubble);
            cleverapps.UI.onClick(bubbleView, this.fogBlock.showBuyWindow.bind(this.fogBlock));
            return bubbleView;
        }, this);

        var bubblesLayout = new cleverapps.Layout(bubbles, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.bubbles.margin
        });
        bubblesLayout.setPositionRound(styles.bubbles);
        this.openerWindow.addChild(bubblesLayout);

        if (!silent) {
            this.openerWindow.setScale(0);
            this.openerWindow.runAction(new cc.ScaleTo(0.3, 1));
        }
    },

    handleBuy: function () {
        this.fogBlock.showBuyWindow();
    },

    addOpenner: function (state, silent) {
        if (state === FogBlock.CANTOPEN) {
            if (!this.bar) {
                this.bar = this.createBar();
                this.addChild(this.bar);
                this.onWandsChanged(silent);

                if (!this.fogBlock.isMoney() && !this.fogBlock.isSpecial()) {
                    var hasOtherTarget = cleverapps.aims.getTarget("map2dWands", { noDefault: true });
                    cleverapps.aims.registerTarget("map2dWands", this.icon, {
                        priority: 1 / (this.fogBlock.getOpenPrice() + hasOtherTarget ? 1 : 0),
                        filter: function () {
                            return this.fogBlock;
                        }.bind(this),
                        scrollsToTarget: function () {
                            if (Game.currentGame.tutorial.isActive()) {
                                return false;
                            }

                            return this.fogBlock && this.fogBlock.getOpenPrice() <= Game.currentGame.wands;
                        }.bind(this),
                        animate: this.animateIcon.bind(this)
                    });
                }

                if (!silent) {
                    this.bar.setScale(0);
                    this.bar.runAction(new cc.ScaleTo(0.3, 1));
                }
            }
        } else {
            this.hideOpener();
        }
    },

    animateIcon: function () {
        if (this.icon) {
            this.icon.stopAllActions();
            this.icon.setScale(1);
            this.icon.runAction(
                new cc.Sequence(
                    new cc.ScaleTo(0.05, 1.2).easing(cc.easeIn(1)),
                    new cc.ScaleTo(0.05, 1).easing(cc.easeOut(1))
                )
            );
        }
    },

    addOpennerWindow: function (state, silent) {
        if (state === FogBlock.CANOPEN) {
            if (this.fogBlock.isMoney()) {
                this.addMoneyOpennerWindow(silent);
                return;
            }

            this.openerWindow = this.createOpenerWindow();
            this.addChild(this.openerWindow);

            if (!silent) {
                this.openerWindow.setScale(0);
                this.openerWindow.runAction(new cc.ScaleTo(0.3, 1));
            }

            if (!silent || !FingerView.currentFinger) {
                this.updatePromptFinger();
            }
        } else {
            this.hideOpenerWindow();
        }
    },

    removeBalloons: function () {
        var balloons = this.balloons;
        var balloonsAnimation = this.balloonsAnimation;

        if (balloons) {
            delete this.balloons;
            delete this.balloonsAnimation;

            balloonsAnimation.setAnimation(0, "close", false);
            balloonsAnimation.setCompleteListener(function () {
                balloonsAnimation.setCompleteListener(null);
                balloons.removeFromParent();
            });

            if (balloons.hoverHandler) {
                balloons.hoverHandler.remove();
                balloons.hoverHandler = undefined;
            }

            cc.eventManager.removeListeners(balloons);
        }
    },

    updatePromptFinger: function () {
        if (!this.openerWindow || this.openerWindow.button.finger) {
            return;
        }

        if (Game.currentGame.tutorial.isActive() || this.fogBlock !== this.fogBlock.fogs.findAvailableFogBlock()) {
            return;
        }

        this.openerWindow.button.finger = FingerView.hintClick(this.openerWindow.button, {
            delay: 0.8,
            runOnce: true
        });
    },

    onUpdateState: function (state, silent) {
        if (!this.fogBlock.isFree()) {
            this.addOpenner(state, silent);
        }
        if (silent || !this.fogBlock.isFree()) {
            this.addOpennerWindow(state, silent);
        }
    },

    onUpdateBlocker: function (state, silent) {
        this.addBlocker(state, silent);
    },

    handleOpen: function () {
        if (this.fogBlock) {
            this.fogBlock.open();
        }
    },

    onOpen: function () {
        this.fogBlock = undefined;

        var delay = this.collectBubbles();

        this.hideBlocker();
        this.hideOpener();
        this.hideOpenerWindow(delay);
        this.removeBalloons();

        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3 + delay),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FOG_BLOCKER_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    WAND_PROGRESS_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.FogBlockView = {
    openerWindow: {
        x: { align: "center" },
        y: { align: "top", dy: 70 },

        button: {
            x: { align: "center" },
            y: { align: "bottom", dy: -80 },
            free: {
                x: { align: "center" },
                y: { align: "bottom" }
            },
            width: 240,
            height: 70
        },

        money: {
            x: { align: "center" },
            y: { align: "top", dy: 60 },

            sellerIcon: {
                x: { align: "center" },
                y: { align: "center" }
            },

            bubbles: {
                x: { align: "center" },
                y: { align: "center", dy: -100 },
                margin: 20
            }
        }
    },

    opener: {
        width: 280,
        height: 60,
        x: { align: "center" },
        y: { align: "top", dy: 70 },

        text: {
            width: 200,
            x: { align: "center", dx: 14 },
            y: { align: "center", dy: 2 }
        },
        icon: {
            x: { align: "left", dx: -4 },
            y: { align: "center", dy: 10 }
        },
        blick: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 2 }
        }
    },

    blocker: {
        width: 100,
        height: 100,
        margin: 25,

        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};
