/**
 * Created by iamso on 23.03.2020.
 */

var HomeTasks = function (home) {
    this.home = home;
    this.actives = undefined;
};

HomeTasks.prototype.add = function (furniture) {
    this.actives.push(furniture);

    if (furniture.id > this.home.getCurrentFurnitureId()) {
        this.home.setCurrentFurnitureId(furniture.id);
    }
};

HomeTasks.prototype.isActive = function (furniture) {
    for (var i in this.actives) {
        if (this.actives[i].id === furniture.id) {
            return true;
        }
    }
    return false;
};

HomeTasks.prototype.onFurnitureCompleted = function (furniture) {
    for (var id in this.actives) {
        if (this.actives[id].id === furniture.id) {
            this.actives.splice(id, 1);
        }
    }
};

HomeTasks.prototype.setActives = function () {
    this.actives = this.home.getActiveFurniture();
};