/**
 * Created by iamso on 25.03.2020.
 */

var NewTaskAnimation = cc.Node.extend({
    ctor: function (furniture, scene, showDelay) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(cleverapps.styles.NewTaskAnimation);

        this.addBg();
        this.addTitle(furniture);
        this.addIcon(furniture);

        this.show();
        this.hide(showDelay || 2);
    },

    show: function () {
        this.bgAnimation.setScaleX(-1);
        this.bgAnimation.setAnimation(0, "animation", false);

        this.title.runAction(new cc.Sequence(
            new cc.DelayTime(0.65),
            new cc.FadeIn(0.1)
        ));

        this.unitIcon.setScale(0.2);
        this.unitIcon.runAction(new cc.Sequence(
            new cc.DelayTime(0.65),
            new cc.Show(),
            new cc.ScaleTo(0.1, 1)
        ));
    },

    hide: function (delay) {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(delay),
            new cc.MoveBy(0.5, -this.width * 2, 0).easing(cc.easeIn(1)),
            new cc.RemoveSelf()
        ));
    },

    addBg: function () {
        var animation = this.bgAnimation = new cleverapps.Spine(bundles.homefix.jsons.hometask_newtask_json);
        this.addChild(animation);
        animation.setPositionRound(this.width / 2, this.height / 2);
    },

    addTitle: function (furniture) {
        var title = this.title = cleverapps.UI.generateTTFText(Messages.get(furniture.getInfo() ? furniture.getInfo().title : "furniture"), cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        title.fitTo(cleverapps.styles.NewTaskAnimation.title.width);
        this.addChild(title);
        title.setPositionRound(cleverapps.styles.NewTaskAnimation.title);
        title.setOpacity(0);
    },

    addIcon: function (furniture) {
        var styles = cleverapps.styles.NewTaskAnimation.furniture;
        var unitIcon = this.unitIcon = UnitView.getUnitImage(furniture.unit);
        cleverapps.UI.fitToBox(unitIcon, styles);
        unitIcon.setPositionRound(styles);
        unitIcon.setVisible(false);
        this.addChild(unitIcon);
    }
});

cleverapps.styles.NewTaskAnimation = {
    width: 260,
    height: 340,

    title: {
        width: 245,
        x: { align: "center", dx: 5 },
        y: { align: "top", dy: -17 }
    },

    furniture: {
        width: 160,
        height: 160,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -10 }
    }
};