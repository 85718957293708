/**
 * Created by evgenijsenkevic on 01.10.2024
 */

FogTileView.prototype.showGroundStateAnimation = cleverapps.extendFunc(FogTileView.prototype.showGroundStateAnimation, function () {
    this._super.apply(this, arguments);

    this.addParticles();
    this.addLight();
});

FogTileView.prototype.addFakeUnitNode = function (fakeUnit) {
    var unitNode = this.unitNode = new cc.Node();
    var unitImage = UnitView.getUnitImage(fakeUnit);
    this.unitNode.addChild(unitImage);
    this.unitNode.unitImage = unitImage;
    Map2d.currentMap.getView().addTile(Map2d.ABOVE_GROUND, this.fogTile.x, this.fogTile.y, unitNode);
};

FogTileView.prototype.removeFromParent = cleverapps.extendFunc(FogTileView.prototype.removeFromParent, function () {
    this._super.apply(this, arguments);

    if (this.light) {
        this.light.removeFromParent();
        delete this.light;
    }
});

FogTileView.prototype.addParticles = function () {
    var particles = new cleverapps.Spine(bundles.fog.jsons.fog_particles);
    particles.setAnimation(0, "animation", true);
    particles.setTimeScale(0.5 + Math.random());
    this.sprite.addChild(particles);
};

FogTileView.prototype.addLight = function () {
    for (var i = -1; i <= 1; i++) {
        for (var j = -1; j <= 1; j++) {
            var x = this.fogTile.x + i;
            var y = this.fogTile.y + j;

            var fog = Map2d.currentMap.getFog(x, y);

            if (!fog || this.fogTile.fogBlock.id !== fog.fogBlock.id) {
                return;
            }
        }
    }

    if (this.fogTile.x % 3 !== 0 || this.fogTile.y % 2 !== 0) {
        return;
    }

    var light = this.light = new cleverapps.Spine(bundles.fog.jsons.fog_light_ray);

    light.setAnimation(0, "animation", true);
    light.setTimeScale(0.5 + Math.random());

    var map2dView = Map2d.currentMap.getView();
    map2dView.alignViewInIsometricGrid(this.fogTile.x, this.fogTile.y, light);
    map2dView.animations.addChild(light);
};