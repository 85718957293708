/**
 * Created by andrey on 09.02.2021.
 */

var Map2dDecorator = function (options) {
    Object.assign(this, options);

    // migration
    if (this.scale) {
        this.scaleY = this.scale;
        this.scaleX = this.scale;
    }

    this.lastSmile = 0;

    this.isOnScreen = function () {};
    this.onSmile = function () {};
    this.getView = function () {};
};

Map2dDecorator.prototype.getLayerId = function () {
    var map = Map2d.currentMap;

    if (map.layers[Map2d.LAYER_GROUND][this.y][this.x] !== undefined) {
        return this.code.indexOf("grounddecorator_") !== -1 ? Map2d.LAYER_GROUND : Map2d.ABOVE_GROUND;
    }
    return map.layers[Map2d.LAYER_BORDERS][this.y][this.x] !== undefined ? Map2d.LAYER_BORDERS : Map2d.LAYER_WATER;
};

Map2dDecorator.prototype.hasSmile = function () {
    if (Map2dDecorator.smilesCache[this.code] === undefined) {
        Map2dDecorator.smilesCache[this.code] = false;

        var json = Map2dDecoratorView.getJson(this);
        if (json) {
            var data = json.resolve().getJson();
            if (data && data.animations && data.animations.smile) {
                Map2dDecorator.smilesCache[this.code] = true;
            }
        }
    }
    return Map2dDecorator.smilesCache[this.code];
};

Map2dDecorator.prototype.isLarge = function () {
    return this.largeBox !== undefined;
};

Map2dDecorator.prototype.getLargeBox = function () {
    if (resolutionScale < 1 && !this.largeBoxScaled) {
        this.largeBoxScaled = cc.rect(
            Math.floor(this.largeBox.x * resolutionScale),
            Math.floor(this.largeBox.y * resolutionScale),
            Math.ceil(this.largeBox.width * resolutionScale),
            Math.ceil(this.largeBox.height * resolutionScale)
        );
    }
    return resolutionScale < 1 ? this.largeBoxScaled : this.largeBox;
};

Map2dDecorator.prototype.getCellRect = function () {
    if (this.cellRect) {
        return this.cellRect;
    }

    var styles = cleverapps.styles.Map2dView.cell;
    var position = Map2d.currentMap.getView().alignInIsometricGrid(this.x, this.y);
    var cellRect = cc.rect(position.x - styles.width / 2, position.y - styles.height / 2, styles.width, styles.height);

    if (!cleverapps.config.editorMode) {
        this.cellRect = cellRect;
    }

    return cellRect;
};

Map2dDecorator.prototype.canSmile = function () {
    if (!this.hasSmile() || Date.now() <= this.lastSmile + Map2dDecorator.SMILE_TIMEOUT || !this.isOnScreen()) {
        return false;
    }

    var fog = Map2d.currentMap.getFog(this.x, this.y);
    if (fog && [FogBlock.UNAVAILABLE, FogBlock.NOTREADY].indexOf(fog.fogBlock.state) !== -1) {
        return false;
    }

    return true;
};

Map2dDecorator.prototype.smile = function () {
    this.lastSmile = Date.now();
    this.onSmile();
};

Map2dDecorator.prototype.setAnimation = function (animation) {
    this.animation = animation;

    if (typeof this.getView() === "undefined") {
        return;
    }

    this.getView().setAnimation(animation);
};

Map2dDecorator.prototype.createView = function () {
    var map = Map2d.currentMap;
    var mapView = map.getView();

    map.setPointer(this.x, this.y);

    var ViewClass = this.getViewClass();

    var view = new ViewClass(this);
    view.setLocalZOrder(1);

    mapView.addTile(this.getLayerId(), this.x, this.y, view);
    return view;
};

Map2dDecorator.prototype.getViewClass = function () {
    return Map2dDecoratorView;
};

Map2dDecorator.SMILE_TIMEOUT = cleverapps.parseInterval("3 seconds");

Map2dDecorator.smilesCache = {};