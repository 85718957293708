/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PATH_COLOR: new cc.Color(255, 230, 180, 255),
    PATH_HARD_COLOR: new cc.Color(145, 56, 188, 255),
    COLOR_LIGHTBROWN: new cc.Color(140, 107, 87, 255),
    COLOR_VICTORY: new cc.Color(240, 73, 0, 255),
    PLAYER_ALARM_COLOR: new cc.Color(252, 148, 37, 255),
    DIALOGUE_PERSON_TEXT_COLOR: new cc.Color(255, 255, 255, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    LETTER_SHADOW: {
        color: new cc.Color(255, 246, 200),
        direction: cc.size(0, -1),
        blur: 2
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LIGHT_WINDOW_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_LIGHTBROWN
    },

    LETTERS_TEXT: {
        size: 90,
        color: cleverapps.styles.COLORS.LETTER,
        shadow: cleverapps.styles.DECORATORS.LETTER_SHADOW,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    DESCRIPTION_TEXT: {
        size: 27,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    DIALOGUE_PERSON_TITLE_TEXT: {
        color: cleverapps.styles.COLORS.DIALOGUE_PERSON_TEXT_COLOR,
        size: 35
    },

    LOADING_ANIMATION_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    PRODUCT_TILE_LIMIT_TEXT: {
        name: "nostroke",
        size: 20,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    CLOVERS_TEXT: {
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        name: "nostroke"
    },

    AMOUNT_BOOSTER: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.overrideStyles(cleverapps.styles.HintButtonBooster, {
    width: 228,
    height: 120,
    position: {
        y: [{ align: "center", dy: -170 },
            { align: "top", dy: -710 },
            { align: "top", dy: -710 }
        ],
        x: [{ align: "right", dx: -20 },
            { align: "right", dx: -20 },
            { align: "right", dx: -20 }
        ]
    },
    content: {
        freeBlock: {
            textWidth: 115
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterAmountView, {
    background: {
        scale: 0.45
    },
    amount: {
        scale: 0.7
    }
});

cleverapps.overrideStyles(cleverapps.styles.RestoreProgressButton, {
    position: [
        {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: -130 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    sound: {
        delay: 3
    }
});

cleverapps.overrideStyles(cleverapps.styles.CharView, {
    topPadding: -3
});

cleverapps.overrideStyles(cleverapps.styles.LotteryIcon, {
    positions: {
        "bottom": {
            y: { align: "bottom", dy: -60 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CryptexWindow, {
    margin: 40
});

cleverapps.overrideStyles(cleverapps.styles.PlayButtonView, {
    width: 172,
    height: 194,

    button: {
        SKIP_RESOLUTION: {
            padding: {
                left: 0.13,
                right: 0.2,
                bottom: 0.1,
                top: 0.1
            }
        }
    },

    position: [
        {
            x: { align: "right", dx: 0 },
            y: { align: "bottom", dy: 123 }
        },
        {
            x: { align: "right", dx: -14 },
            y: { align: "bottom", dy: 3 }
        },
        {
            x: { align: "right", dx: -14 },
            y: { align: "bottom", dy: 3 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.VkLotteryIcon, {
    positions: {
        "icon": {
            x: { align: "center", dx: 15 },
            y: { align: "bottom", dy: -50 },
            scale: 0.9
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoardView, {
    minSpaceX: 100
});

cleverapps.overrideStyles(cleverapps.styles.KeypadView, {
    controls: {
        mobileScale: 0.7
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 190,
    height: 88,

    icon: {
        x: { align: "left", dx: -20 },
        y: { align: "center", dy: 4 }
    },

    text: {
        x: { align: "center", dx: 30 }
    },

    position: [
        {
            x: { align: "left", dx: 40 },
            y: { align: "bottom", dy: 500 }
        },
        {
            x: { align: "left", dx: 60 },
            y: { align: "bottom", dy: 500 }
        },
        {
            x: { align: "left", dx: 60 },
            y: { align: "bottom", dy: 500 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.TournamentPlayersView, {
    position: [
        {
            x: { align: "center" },
            y: { align: "top", dy: -105 }
        },
        {
            x: { align: "left", dx: -20 },
            y: { align: "top", dy: -165 }
        },
        {
            x: { align: "left", dx: -20 },
            y: { align: "top", dy: -165 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "default": {
            idleDelay: -1.3
        },

        "starterPack0": {
            idleAnimation: "0_idle",
            introAnimation: "0_start"
        },

        "starterPack": {
            idleAnimation: "1_idle",
            introAnimation: "1_start"
        },

        "starterPack2": {
            idleAnimation: "2_idle",
            introAnimation: "2_start"
        }
    },

    rewards: {
        delay: 1.5,
        duration: 0.675,
        fitTo: {
            width: 166
        },

        positions: {
            "default": {
                "hard": {
                    x: 10,
                    y: -205
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.WordGameScene, {
    minimalPosition: {
        x: { align: "center" },
        y: { align: "center", dy: 20 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorX: 0.49,
            factorY: 0.71
        },
        posVert: {
            factorX: 0.49,
            factorY: 0.65
        }
    }
});