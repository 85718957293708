/**
 * Created by andrey on 03.01.2021.
 */

var MakesOrder = function (unit, options) {
    UnitComponent.call(this, unit);

    if (!cleverapps.config.editorMode && !cleverapps.config.wysiwygMode) {
        this.restore(options);
        Game.currentGame.harvested.on("onChangeItems", this.handleIngredientsUpdate.bind(this), this);
    }
};

MakesOrder.prototype = Object.create(UnitComponent.prototype);
MakesOrder.prototype.constructor = MakesOrder;

MakesOrder.prototype.onPrizeHarvested = function () {
    this.reset();
};

MakesOrder.prototype.save = function (data) {
    data.state = this.state;
    data.recipe = this.recipe && this.recipe.code;
    data.timeToReady = this.timeToReady;
};

MakesOrder.prototype.restore = function (data) {
    var recipe = data.recipe && this.restoreRecipe(data.recipe);

    if (!recipe || data.state === MakesOrder.STATE_EMPTY) {
        this._setState(MakesOrder.STATE_EMPTY);
    } else if (data.state === MakesOrder.STATE_RECIPE) {
        this._setState(MakesOrder.STATE_RECIPE, recipe);
    } else if (data.state === MakesOrder.STATE_COOKING) {
        this._setState(MakesOrder.STATE_COOKING, recipe, data.timeToReady - Date.now());
    } else { // (data.state === MakesOrder.STATE_READY)
        this._setState(MakesOrder.STATE_READY, recipe);
    }
};

MakesOrder.prototype.isCooking = function () {
    return this.state === MakesOrder.STATE_COOKING;
};

MakesOrder.prototype.restoreRecipe = function (code) {
    var recipes = Recipes[this.unit.getType()];
    for (var i = 0; i < recipes.length; ++i) {
        var recipe = recipes[i];

        if (recipe.code === code && Game.currentGame.harvested.isIngredientsOpened(recipe.ingredients)) {
            return recipe;
        }
    }
};

MakesOrder.prototype.destructor = function () {
    clearTimeout(this.timeout);
    runCleaners(this);
};

MakesOrder.prototype._setState = function (state, recipe, duration) {
    if (cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        return;
    }

    clearTimeout(this.timeout);

    switch (state) {
        case MakesOrder.STATE_RECIPE:
            this.state = state;
            this.recipe = recipe;
            delete this.timeToReady;
            this.unit.setPrizes({
                sprite: this.canCook() ? bundles.merge.frames.prize_exclamation : bundles.merge.frames.prize_exclamation_off
            });
            break;

        case MakesOrder.STATE_COOKING:
            this.state = state;
            this.recipe = recipe;
            this.timeToReady = Date.now() + duration;
            this.timeout = new cleverapps.LongTimeout(this.enjoy.bind(this), duration);
            this.unit.setPrizes({
                waiting: true
            });
            break;

        case MakesOrder.STATE_READY:
            this.state = state;
            this.recipe = recipe;
            delete this.timeToReady;
            this.unit.setPrizes(this.createPrizeUnits());
            break;

        default:
            this.state = MakesOrder.STATE_EMPTY;
            delete this.recipe;
            delete this.timeToReady;
            this.timeout = new cleverapps.LongTimeout(this.order.bind(this), Game.currentGame.tutorial.isActive() ? MakesOrder.BEGINNER_TIMEOUT : MakesOrder.ORDER_TIMEOUT);
            this.unit.setPrizes();
    }

    this.canCookCache = this.canCook();

    Game.currentGame.orders.updateOrder(this);
    cleverapps.toolbar.resetByType(ToolbarItem.ORDERS);
};

MakesOrder.prototype.handleIngredientsUpdate = function () {
    var canCook = this.canCook();
    if (this.canCookCache !== canCook) {
        this.canCookCache = canCook;
        this.unit.setPrizes({
            sprite: this.canCookCache ? bundles.merge.frames.prize_exclamation : bundles.merge.frames.prize_exclamation_off
        });
    }
};

MakesOrder.prototype.order = function () {
    var recipe = this.chooseFreeRecipe();
    if (recipe) {
        this._setState(MakesOrder.STATE_RECIPE, recipe);
        cleverapps.audio.playSound(bundles.merge.urls.new_order_effect);
    } else {
        this.reset();
    }
};

MakesOrder.prototype.canCook = function () {
    return this.state === MakesOrder.STATE_RECIPE && Game.currentGame.harvested.canTakeList(this.recipe.ingredients);
};

MakesOrder.prototype.isReady = function () {
    return this.state === MakesOrder.STATE_READY;
};

MakesOrder.prototype.cook = function () {
    if (this.canCook()) {
        Game.currentGame.harvested.takeList(this.recipe.ingredients);
        this._setState(MakesOrder.STATE_COOKING, this.recipe, cleverapps.parseInterval(this.recipe.time));
        Game.currentGame.pushes.updateOrders();
        return true;
    }
    return false;
};

MakesOrder.prototype.enjoy = function () {
    if (this.state === MakesOrder.STATE_COOKING) {
        this._setState(MakesOrder.STATE_READY, this.recipe);
        Game.currentGame.pushes.updateOrders();
        return true;
    }
    return false;
};

MakesOrder.prototype.reset = function () {
    this._setState(MakesOrder.STATE_EMPTY);
};

MakesOrder.prototype.handlePrizes = function () {
    if ([MakesOrder.STATE_EMPTY, MakesOrder.STATE_READY].indexOf(this.state) !== -1) {
        return false;
    }

    cleverapps.meta.display({
        stack: Game.currentGame.tutorial.checkTargets(this.unit),
        focus: "openOrdersWindow",
        action: function (f) {
            new OrdersWindow({
                maker: this
            });
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });

    return true;
};

MakesOrder.prototype.getTimeLeft = function () {
    return Math.max((this.timeToReady - Date.now()) || 0, 0);
};

MakesOrder.prototype.createPrizeUnits = function () {
    if (this.recipe) {
        return this.recipe.prize.slice();
    }
};

MakesOrder.prototype.chooseFreeRecipe = function () {
    var recipes = Recipes[this.unit.getType()].filter(function (recipe, id) {
        if (recipe.level > cleverapps.gameLevel.getLevel()) {
            return false;
        }

        return id > 0
            && Game.currentGame.harvested.isIngredientsOpened(recipe.ingredients)
            && Game.currentGame.orders.listMakers().every(function (maker) {
                return maker.recipe !== recipe;
            });
    });

    if (recipes.length === 0 && cleverapps.gameLevel.getLevel() > 1) {
        return Recipes[this.unit.getType()][0];
    }

    if (recipes.length <= 3) {
        return cleverapps.Random.choose(recipes);
    }

    var id = recipes.length - 1;
    while (id > 0 && cleverapps.Random.nextDouble() < 0.6) {
        id--;
    }
    return recipes[id];
};

MakesOrder.BEGINNER_TIMEOUT = cleverapps.parseInterval("120 seconds");
MakesOrder.ORDER_TIMEOUT = cleverapps.parseInterval("30 seconds");

MakesOrder.STATE_EMPTY = 0;
MakesOrder.STATE_RECIPE = 1;
MakesOrder.STATE_COOKING = 2;
MakesOrder.STATE_READY = 3;
