/**
 * Created by r4zi4l on 15.03.2021
 */

var GuideWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options, unit) {
        this.stages = [];
        this.arrows = [];
        this.unit = unit;
        this.name = options.name;
        this.bundle = options.bundle || options.getGuideBundle();

        if (this.listSpines().length) {
            this.stagesContent = this.createSpineStages();
        } else if (options.stagesContent) {
            this.stagesContent = options.stagesContent.call(this, unit);
        } else {
            this.stagesContent = [new cc.Node(), new cc.Node(), new cc.Node()];
            cleverapps.throwAsync("no content for guide window " + options.name);
        }

        this._super({
            name: options.name,
            title: this.unit ? cleverapps.unitsLibrary.getUnitName(this.unit) : options.title || options.name + ".title",
            content: this.createContent(),
            noBackground: true,
            closeButton: true
        });

        this.showStages();
        this.showArrows();
    },

    isVertical: function () {
        return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
    },

    showStages: function () {
        var delay = 0.12;
        this.stages.forEach(function (stage) {
            var description = stage.children[1];
            description.setOpacity(0);
            description.setScale(0.8);

            stage.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.ScaleTo(0, 0.5),
                new cc.DelayTime(delay),
                new cc.Show(),
                new cc.CallFunc(function () {
                    description.runAction(
                        new cc.Sequence(
                            new cc.DelayTime(0.3),
                            new cc.Spawn(
                                new cc.ScaleTo(0.2, 1),
                                new cc.FadeIn(0.4)
                            )
                        )
                    );
                }),
                new cc.ScaleTo(0.5, stage.scale).easing(cc.easeBackOut())
            ));

            delay += 0.6;
        }, this);
    },

    showArrows: function () {
        var delay = 0;
        this.arrows.forEach(function (arrow, index) {
            var animation = this.isVertical() ? "vertical" : "horizontal";
            if (index === 1 && cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
                animation = "diagonal";
            }

            arrow.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.DelayTime(delay),
                new cc.CallFunc(function () {
                    arrow.spine.setAnimation(0, animation);
                }),
                new cc.Show()
            ));
            delay += 0.4;
        }, this);
    },

    createContent: function () {
        var styles = cleverapps.styles.GuideWindow;

        var stage1 = this.createStage(this.stagesContent[0], this.name + ".firstStage");
        var stage2 = this.createStage(this.stagesContent[1], this.name + ".secondStage");
        var stage3 = this.createStage(this.stagesContent[2], this.name + ".thirdStage");

        this.stageSize = cc.size(stage1.width, stage1.height);

        var arrow1 = this.createArrow(1);
        var arrow2 = this.createArrow(2);

        this.stages = [stage1, stage2, stage3];
        this.arrows = [arrow1, arrow2];

        var stages;
        var margin = styles.defaultMargin[cleverapps.resolution.mode];
        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            var row1 = new cleverapps.Layout([stage1, arrow1, stage2], { direction: cleverapps.UI.HORIZONTAL, margin: margin });
            var alignNode = new cc.Node();
            alignNode.setContentSize(arrow2.getContentSize());

            [alignNode, stage3, arrow2].forEach(function (node) {
                node.setAnchorPoint(0.5, 1);
            });

            var row2 = new cleverapps.Layout([alignNode, stage3, arrow2], {
                direction: cleverapps.UI.HORIZONTAL
            });
            stages = new cleverapps.Layout([row1, row2], { direction: cleverapps.UI.VERTICAL, margin: margin });
        } else {
            if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
                margin = Math.max(margin, (cleverapps.scenes.getRunningScene().width * 0.8 - stage1.width * 3 - arrow1.width * 2) / 4);
            }
            stages = new cleverapps.Layout([stage1, arrow1, stage2, arrow2, stage3], {
                direction: this.isVertical() ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
                margin: margin,
                padding: !this.isVertical() ? styles.padding : undefined
            });
        }

        cleverapps.UI.onClick(stages, this.close.bind(this), {
            interactiveScale: false
        });

        return stages;
    },

    createArrow: function (index) {
        var styles = cleverapps.styles.GuideWindow.stages;
        var spine = new cleverapps.Spine(this.bundle.jsons.arrows);
        spine.setAnchorPoint(0.5, 0.5);

        var arrowWrapper = new cc.Node();
        arrowWrapper.spine = spine;

        if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
            arrowWrapper.setContentSize(spine.width, this.stageSize.height);
            spine.setPositionRound(arrowWrapper.width / 2, styles.description.height + styles.margin + this.stagesContent[0].height / 2);
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.SQUARE) {
            arrowWrapper.setContentSize(spine.width, this.stageSize.height);
            var position = cc.p(arrowWrapper.width / 2, styles.description.height + styles.margin + this.stagesContent[0].height / 2);
            if (index === 2) {
                position.y += this.stagesContent[0].height / 2;
            }
            spine.setPositionRound(position);
        }

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            arrowWrapper.setContentSize(this.stageSize.width, spine.height);
            spine.setPositionRound(arrowWrapper.width / 2, arrowWrapper.height / 2);
        }
        arrowWrapper.setAnchorPoint(0.5, 0.5);
        arrowWrapper.addChild(spine);
        return arrowWrapper;
    },

    createStage: function (stageView, descriptionText) {
        var styles = cleverapps.styles.GuideWindow.stages;

        var description = cleverapps.UI.generateOnlyText(descriptionText, cleverapps.styles.FONTS.GUIDE_WINDOW_STAGE_TEXT);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        description.setDimensions(this.isVertical() ? styles.description.verticalWidth : styles.description.width, 0);
        description.fitTo(undefined, styles.description.height);
        description.setAnchorPoint(0.5, 0.5);

        var size = cc.size(styles.description.width, styles.description.height);
        if (this.isVertical()) {
            size.width = styles.description.verticalWidth;
        }

        var descriptionNode = new cc.Node();
        descriptionNode.setContentSize2(size);
        descriptionNode.addChild(description);
        descriptionNode.setAnchorPoint(0.5, 0.5);
        description.setPositionRound(descriptionNode.width / 2, descriptionNode.height / 2);

        return new cleverapps.Layout([stageView, descriptionNode], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    initStage: function (styles, callback) {
        var stage = new cc.Node();
        stage.setAnchorPoint(0.5, 0.5);
        stage.setContentSize2(cleverapps.styles.GuideWindow.stages);

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setPositionRound(styles.container.x, styles.container.y);
        stage.addChild(container);

        callback(styles, container);

        return stage;
    },

    addUnitIcon: function (parent, code, stage, styles) {
        var icon = UnitView.getUnitImage({ code: code, stage: stage }, { alignAnchorX: true, preferStatic: true, alignAnchorY: styles.alignAnchorY });
        icon.setPositionRound(styles);

        var size = cc.size(styles.width, styles.height);
        if (styles.padding) {
            size = cc.rectSubPadding(cc.rect(0, 0, cleverapps.styles.GuideWindow.stages.width, cleverapps.styles.GuideWindow.stages.height), cc.padding(styles.padding));
        }

        if (size.width || size.height) {
            cleverapps.UI.fitToBox(icon, {
                width: size.width,
                height: size.height,
                maxScale: styles.scale
            });
        } else if (styles.scale) {
            icon.setScale(styles.scale);
        }

        parent.addChild(icon);
        return icon;
    },

    addSprite: function (parent, resource, styles) {
        if (styles === false || !styles) {
            return new cc.Node();
        }
        var sprite = new cc.Sprite(resource);
        sprite.setAnchorPoint(0.5, 0.5);
        sprite.setPositionRound(styles.x, styles.y);
        if (styles.scale) {
            sprite.setScale(styles.scale);
        }
        if (styles.rotation) {
            sprite.setRotation(styles.rotation);
        }
        parent.addChild(sprite, styles.zOrder);
        return sprite;
    },

    createSpineStages: function (jsons) {
        jsons = jsons || this.listSpines();

        return jsons.map(function (json, index) {
            var spine = new cleverapps.Spine(json);
            spine.setAnimation(0, "stage" + (index + 1), true);
            spine.setAnchorPoint(0.5, 0.5);

            return spine;
        }, this);
    },

    listSpines: function () {
        return [
            this.bundle.jsons.guide_stages_1 || this.bundle.jsons.guide_stages,
            this.bundle.jsons.guide_stages_2 || this.bundle.jsons.guide_stages,
            this.bundle.jsons.guide_stages_3 || this.bundle.jsons.guide_stages
        ].filter(Boolean);
    },

    listBundles: function (options, unit) {
        var bundles = [(options.bundle || options.getGuideBundle()).getBundleName()];
        if (!unit) {
            return bundles;
        }

        Families[unit.code].units.forEach(function (data, stage) {
            if (data.lazy) {
                bundles.push(UnitView.GetUnitBundle({ code: unit.code, stage: stage }));
            }
        });

        return bundles;
    }
});

GuideWindow.createMissionRulesWindow = function (mission) {
    return new GuideWindow({
        name: mission.startWindow.name,

        getGuideBundle: function () {
            return mission.startWindow.bundle;
        },

        stagesContent: function () {
            return this.createSpineStages([
                this.bundle.jsons.rules_stages_1 || this.bundle.jsons.rules_json,
                this.bundle.jsons.rules_stages_2 || this.bundle.jsons.rules_json,
                this.bundle.jsons.rules_stages_3 || this.bundle.jsons.rules_json
            ]);
        }
    });
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    GUIDE_WINDOW_STAGE_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        lineHeight: 40
    },
    GUIDE_WINDOW_CLOSE_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    GUIDEWINDOW_DESCRIPTION_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        lineHeight: 40
    }
});

cleverapps.styles.GuideWindow = {
    stages: {
        width: 400,
        height: 520,
        margin: 50,

        description: {
            width: 320,
            verticalWidth: 500,
            height: 156
        }
    },

    defaultMargin: [30, 80, 120],

    arrow: {
        x: { align: "center" },
        y: { align: "center", dy: 20 }
    },

    closeText: {
        x: { align: "center" },
        y: { align: "bottom", dy: -126 }
    },

    description: {
        width: 1160,
        verticalWidth: 700
    },

    padding: {
        top: 60,
        bottom: 60,
        left: 60,
        right: 60
    }
};
