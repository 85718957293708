/**
 * Created by iamso on 24.12.2021
 */

var Portal = function (unit, options) {
    UnitComponent.call(this, unit);

    this.time = options.time || 0;

    var timeLeft = this.getTimeLeft();
    this.running = false;
    if (timeLeft > 0) {
        this.running = true;
        this.timeout = new cleverapps.LongTimeout(this.complete.bind(this), timeLeft);
    }
};

Portal.prototype = Object.create(UnitComponent.prototype);
Portal.prototype.constructor = Portal;

Portal.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    } 
};

Portal.prototype.onTeleportUnit = function (unit) {
    if (this.view) {
        this.view.teleportUnit(unit);
    } 
};

Portal.prototype.canAcceptDraggable = function (unit) {
    if (this.running) {
        return false;
    }

    return Portal.ACCEPTED_CODES.indexOf(unit.code) !== -1;
};

Portal.prototype.teleportUnit = function (unit) {
    var pocket = new Pocket();
    pocket.addUnits(unit);
    unit.getDnDAccepted(this.unit);

    this.onTeleportUnit(unit);
};

Portal.prototype.acceptDraggable = function (unit) {
    this.time = Date.now();
    this.running = true;
    this.timeout = new cleverapps.LongTimeout(this.complete.bind(this), this.getTimeLeft());
    this.unit.save();

    this.teleportUnit(unit);
};

Portal.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

Portal.prototype.complete = function () {
    clearTimeout(this.timeout);
    this.running = false;
    this.time = 0;
    this.unit.save();
    this.onUpdateState();
};

Portal.prototype.getTimeLeft = function () {
    return Math.max(0, this.time + Portal.REFRESH_DURATION - Date.now());
};

Portal.prototype.save = function (data) {
    if (this.time) {
        data.time = this.time;
    }
};

Portal.prototype.openGuideWindow = function () {
    cleverapps.meta.display({
        focus: "PortalGuideWindow",
        action: function (f) {
            new GuideWindow(PortalGuideOptions, this.unit);
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
};

Portal.prototype.handleClick = function () {
    if (!this.running) {
        this.unit.squeeze();
        this.openGuideWindow();
        return true;
    }
};

Portal.prototype.getActionInfo = function () {
    if (this.running) {
        return {
            title: this.unit,
            countdown: this.getTimeLeft(),
            buttons: {
                speedup: {
                    unit: this.unit,
                    action: this.complete.bind(this),
                    duration: this.getTimeLeft()
                }
            }
        };
    }
};

Portal.REFRESH_DURATION = cleverapps.parseInterval("24 hours");
Portal.ACCEPTED_CODES = ["crystal", "worker"];