/**
 * Created by andrey on 20.05.2021.
 */

var MiniGame = function (unit, options) {
    UnitComponent.call(this, unit);

    this.sinceLastHarvest = undefined;
    this.harvestDuration = cleverapps.parseInterval(MiniGame.HARVEST_INTERVAL);

    this.sinceLastHarvest = options.sinceLastHarvest;
    if (this.isPrizeReady()) {
        this.sinceLastHarvest = undefined;
        this.unit.setPrizes({
            minigame: true
        });
    } else {
        this.timeout = new cleverapps.LongTimeout(this.makeReady.bind(this), this.timeToReady());
    }
};

MiniGame.prototype = Object.create(UnitComponent.prototype);
MiniGame.prototype.constructor = MiniGame;

MiniGame.prototype.onPrizeHarvested = function () {
    this.sinceLastHarvest = Date.now();
    clearTimeout(this.timeout);
    this.timeout = new cleverapps.LongTimeout(this.makeReady.bind(this), this.timeToReady());
    this.unit.save();
    this.onUpdateState();

    Game.currentGame.pushes.updateMiniGames();
};

MiniGame.prototype.isWaiting = function () {
    return this.sinceLastHarvest !== undefined;
};

MiniGame.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    } 
};

MiniGame.prototype.handlePrizes = function () {
    if (!this.unit.prizes.minigame) {
        return false;
    }

    cleverapps.meta.display({
        focus: "MinesweeperScene",
        actions: [
            function (f) {
                this.unit.setPrizes(undefined);
                this.unit.onPrizeHarvested();

                setTimeout(f, 300);
            }.bind(this),

            function (f) {
                var saver = GameDataSaver.Create("minigame");
                saver.add({
                    x: this.unit.x,
                    y: this.unit.y
                });

                Map2d.currentMap.setScrollCell(this.unit);
                cleverapps.scenes.replaceScene(new MineGameScene({
                    saver: saver
                }), f);
            }.bind(this)
        ]
    });

    return true;
};

MiniGame.prototype.isPrizeReady = function () {
    return this.sinceLastHarvest === undefined || this.timeToReady() === 0;
};

MiniGame.prototype.save = function (data) {
    data.sinceLastHarvest = this.sinceLastHarvest;
};

MiniGame.prototype.getActionInfo = function () {
    return {
        title: this.unit,
        countdown: this.timeToReady(),
        buttons: {
            speedup: {
                unit: this.unit,
                action: this.makeReady.bind(this),
                duration: this.timeToReady()
            }
        }
    };
};

MiniGame.prototype.destructor = function () {
    clearTimeout(this.timeout);
};

MiniGame.prototype.makeReady = function () {
    clearTimeout(this.timeout);

    this.sinceLastHarvest = undefined;
    this.unit.setPrizes({
        minigame: true
    });
    this.unit.save();
    this.onUpdateState();

    Game.currentGame.pushes.updateMiniGames();
};

MiniGame.prototype.timeToReady = function () {
    return Math.max(0, this.sinceLastHarvest + this.harvestDuration - Date.now());
};

MiniGame.ProcessRewardsStage = function (f) {
    var merge = Game.currentGame;
    var data = GameDataSaver.Create("minigame").loadAndClear();
    var unit = data && merge.map.getUnit(data.x, data.y);
    var reward = data && data.reward;

    if (!unit || !reward) {
        f();
        return;
    }

    cleverapps.meta.compound(f, [
        function (f) {
            merge.map.getView().scroll.runAction(new cc.Sequence(
                new cc.CellScrollAction(data, {
                    duration: merge.map.getCenterDistance(data.x, data.y) <= 10 ? 0.5 : 1,
                    allowScrollWithFocus: true
                }).easing(cc.easeInOut(2)),
                new cc.CallFunc(f)
            ));
        },

        function (f) {
            reward.rubies = reward.hard;

            ["soft", "energy", "rubies"].forEach(function (type) {
                if (reward[type]) {
                    Game.currentGame.addReward(type, reward[type], unit, {
                        withoutDelta: true,
                        event: cleverapps.EVENTS.EARN.DAILY
                    });
                }
            });

            if (reward.canceled) {
                unit.findComponent(MiniGame).makeReady();
            }

            Game.currentGame.counter.setTimeout(f, 1000);
        }
    ]);
};

MiniGame.HARVEST_INTERVAL = "6 hours";
