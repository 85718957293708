/**
 * Created by evgeny on 07.06.2024
 */

var Homefix = function (location, locationId) {
    cleverapps.EventEmitter.call(this);

    var map = this.map = new Map2d(location.map, {
        tiles: location.tiles,
        units: location.units,
        visibleBox: location.visibleBox,
        regions: location.regions,
        decorators: location.decorators,
        terrains: location.terrains,
        field: location.field,
        families: location.families
    });

    this.map.onUnitAvailableListener = this.onUnitAvailable.bind(this);

    var updateMap = function () {
        cleverapps.home.setMapInfo(locationId, map.getInfo());
    };

    map.on("saveUnit", updateMap);
    map.on("removeUnit", updateMap);
    map.on("saveFogs", updateMap);

    if (!cleverapps.config.editorMode) {
        if (cleverapps.home.oldItems) {
            this.map.initFogs(["fog0"]);
            this.migrateOldItems(cleverapps.home.oldItems);
            delete cleverapps.home.oldItems;
        } else {
            var stored = cleverapps.home.getMapInfo(locationId);
            this.map.initFogs(stored.fogs);
            this.map.load(stored.units);
        }
        this.map.fogs.initStage();

        cleverapps.config.gameInitialized = true;

        if (this.map.fogs.blocks.fog0) {
            this.map.fogs.blocks.fog0.open();
        }
        cleverapps.home.onUnitsLoaded();
    } else {
        Map2d.InsertDefaults(this.map, location.field);
    }
};

Homefix.prototype = Object.create(cleverapps.EventEmitter.prototype);
Homefix.constructor = Homefix;

Homefix.prototype.migrateOldItems = function (oldItems) {
    Object.keys(oldItems).forEach(function (id) {
        var furniture = cleverapps.home.getFurnitureById(Number(id));
        var item = oldItems[id];

        if (furniture.throwaway) {
            return;
        }

        var fakeUnit = Map2d.currentMap.fogs.findFakeUnit({
            code: furniture.unitCode,
            type: "furniture",
            stage: 0
        });
        if (fakeUnit) {
            fakeUnit.stage = item.choice !== undefined ? item.stage : item.stage - 1;
            fakeUnit.choice = item.choice;
            this.map.loadUnit(fakeUnit.x, fakeUnit.y, fakeUnit);
        }
    }.bind(this));
};

Homefix.prototype.playIntro = function (f) {
    f();
};

Homefix.prototype.stop = function () {
    this.map.stop();
};

Homefix.prototype.getBasicZoom = function () {
    var zoom = 1;

    if (this.map.zoom) {
        zoom = this.map.zoom;
    } else if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
        zoom = connector.info.isMobile ? 1.85 : 1.2;
    }

    if (cleverapps.skins.getSlot("basicZoom", zoom)) {
        zoom = cleverapps.skins.getSlot("basicZoom", zoom);
    }

    if (cleverapps.config.editorMode) {
        zoom = 1;
    }

    if (cleverapps.config.demoMode) {
        zoom = 0.4;
    }

    return zoom;
};

Homefix.prototype.onUnitAvailable = function (unit) {
    cleverapps.unitsLibrary.openUnit(unit);
};