/**
 * Created by r4zi4l on 06.07.2021
 */

var UnitsShopComponent = function (unit) {
    UnitComponent.call(this, unit);

    this.updateTab();

    if (cleverapps.environment.isAdministratorScene()) {
        return;
    }

    this.assignProduct();

    var shop = cleverapps.unitsShop.tabs[this.tab];
    shop.on("stateChanged", this.assignProduct.bind(this), this);

    cleverapps.eventBus.on("unitAvailable", function (unit) {
        if (unit instanceof Unit) {
            if (unit.getType() === "resource" && this.unit.code === "resourceshop") {
                this.assignProduct();
            }

            if (unit.getType() === "unitsshop") {
                cleverapps.toolbar.resetByType(ToolbarItem.UNIT_SHOP);
            }
        }
    }.bind(this), this);
};

UnitsShopComponent.prototype = Object.create(UnitComponent.prototype);
UnitsShopComponent.prototype.constructor = UnitsShopComponent;

UnitsShopComponent.prototype.onShowCrystals = function () {
    if (this.view) {
        this.view.showCrystals();
    }
};

UnitsShopComponent.prototype.onHideCrystals = function () {
    if (this.view) {
        this.view.hideCrystals();
    }
};

UnitsShopComponent.prototype.updateTab = function () {
    var data = this.unit.getData();

    var payerClass = cleverapps.paymentsHistory.classify();
    var vip = cleverapps.paymentsHistory.getVIPStatus();

    if (data.vip_tab && vip.status) {
        this.tab = data.vip_tab;
    } else if (data.undecided_tab && payerClass === cleverapps.PaymentsHistory.BRACKET_UNDECIDED && connector.payments.isConnected()) {
        this.tab = data.undecided_tab;
    } else {
        this.tab = data.tab;
    }

    if (typeof this.tab === "object") {
        this.tab = this.tab[cleverapps.travelBook.getCurrentPage().id];
    }
};

UnitsShopComponent.prototype.isCinema = function () {
    return this.tab === ShopWindow.TABS.UNITS_ADS || this.tab === ShopWindow.TABS.UNITS_ADS_VIP || this.tab === ShopWindow.TABS.UNITS_ADS_UNDECIDED;
};

UnitsShopComponent.prototype.assignProduct = function () {
    if (this.unit.getData().toyfactory) {
        return;
    }

    var shop = cleverapps.unitsShop.tabs[this.tab];
    var product = shop.getFreeProduct();

    if (product) {
        product = [product.unit];
    } else if (shop.fresh) {
        if (!this.isCinema() || cleverapps.rewardedAdsManager.isEnabled()) {
            product = {
                sprite: bundles.merge.frames.prize_exclamation
            };
        }
    }

    if (Unit.Equals(product && product[0], this.product && this.product[0])) {
        return;
    }

    this.product = product;
    this.unit.setPrizes(product);

    this.checkPrizes();
};

UnitsShopComponent.prototype.checkPrizes = function () {
    if (!this.product) {
        if (this.isCrystalsAvailable()) {
            this.unit.setPrizes({ sprite: bundles.merge.frames.bp_crystal_png });
        } else {
            this.unit.setPrizes();
        }
    }
};

UnitsShopComponent.prototype.hasCrystals = function () {
    var mission = cleverapps.missionManager.findByType(Mission.TYPE_SALEPASS);
    return MissionManager.hasProperParent(mission) && this.isCrystalsAvailable();
};

UnitsShopComponent.prototype.checkCrystals = function () {
    if (this.hasCrystals()) {
        this.onShowCrystals();
    } else {
        this.onHideCrystals();
    }
};

UnitsShopComponent.prototype.unitWithSalePass = function () {
    return ["fruitshop", "resourceshop", "discountshop"].indexOf(this.unit.code) !== -1;
};

UnitsShopComponent.prototype.isCrystalsAvailable = function () {
    var mission = cleverapps.missionManager.findByType(Mission.TYPE_SALEPASS);
    return mission && mission.isRunning() && MissionManager.hasProperParent(mission) && !mission.logic.isAllTasksCompleted() && this.unitWithSalePass();
};

UnitsShopComponent.prototype.handlePrizes = function () {
    this.updateTab();

    cleverapps.meta.display({
        stack: Game.currentGame.tutorial.checkTargets(this.unit) && !cleverapps.windows.isActive(),
        focus: "displayUnitsShopComponent" + this.tab,
        control: ["MenuBarGoldItem", "MenuBarCoinsItem"],
        action: function (f) {
            new UnitsShopWindow({ tab: this.tab });
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });

    return true;
};

UnitsShopComponent.prototype.handleClick = function () {
    this.handlePrizes();
    this.unit.squeeze();
    return true;
};

UnitsShopComponent.prototype.destructor = function () {
    runCleaners(this);
};
