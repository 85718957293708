/**
 * Created by evgeny on 14.06.2024
 */

var FamiliesHelper = {
    ShapeSquare: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 }
    ],

    ShapeCell: [
        { x: 0, y: 0 }
    ],

    ShapeTopRightCorner: [
        { x: 0, y: 0 },
        { x: -1, y: 0 },
        { x: 0, y: -1 }
    ],

    Shape3x3: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: 2, y: 1 },
        { x: 0, y: 2 },
        { x: 1, y: 2 },
        { x: 2, y: 2 }
    ],

    Drop: {
        Default: 0,
        Parallel: 1,
        Open: 2
    },

    FurnitureFamily: function (options) {
        var furniture = options.furniture;
        var units = [];
        for (var i = 0; i < furniture.stages.length; i++) {
            units.push({
                homefixId: options.furniture.name,
                highlight: true,
                invisible: options.invisible,
                movable: false,
                throwaway: furniture.throwaway,
                homeTask: options.furniture.name,
                fixable: !furniture.throwaway,
                untouchable: furniture.untouchable || i === furniture.untouchableStage
            });
        }

        if (furniture.choices) {
            units.push({
                homefixId: options.furniture.name,
                highlight: true,
                movable: false,
                invisible: options.invisible,
                fixable: true,
                switchable: options.region ? undefined : furniture.choices,
                switchableDecorators: options.region ? {
                    region: options.region,
                    choices: furniture.choices
                } : undefined
            });
        }

        if (options.openLocation) {
            units[units.length - 1].openLocation = options.openLocation;
        }

        if (options.openLocation) {
            units[units.length - 1].openLocation = options.openLocation;
        }

        return {
            type: "furniture",
            furniture: furniture,
            units: units,
            expeditions: options && options.expeditions
        };
    }
};

if (typeof cc === "undefined") {
    module.exports = FamiliesHelper;
}
