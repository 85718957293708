/**
 * Created by mac on 12/23/20
 */

var GroundedView = cc.Sprite.extend({
    ctor: function (grounded) {
        this._super(GroundedView.getImage(grounded.unit.x, grounded.unit.y));
    },

    onRemove: function (silent) {
        if (silent) {
            this.removeFromParent();
        } else {
            this.runAction(
                new cc.Sequence(
                    new cc.FadeOut(0.5),
                    new cc.RemoveSelf()
                )
            );
        }
    }
});

GroundedView.getImage = function (x, y) {
    var terrain = Map2d.currentMap.getTerrainCode(x, y);
    var tiles = Game.currentGame.level.meta.tiles;
    return bundles["grounded_" + tiles].frames["grounded_" + Map2d.TERRAINS[terrain]];
};