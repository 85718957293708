/**
 * Created by razial on 17.01.2021
 */

var UnitsLibrary = function (slot) {
    this.slot = slot;
    this.heroes = [];
    this.load();
};

UnitsLibrary.prototype.load = function (stored) {
    stored = stored || cleverapps.dataLoader.load(DataLoader.TYPES.UNITS_LIBRARY + this.slot);

    var needSave = false;
    if (!stored && this.slot === CustomSyncers.SLOT_MAIN) {
        stored = needSave = cleverapps.GameSaver.loadProperty(CustomSyncers.SLOT_MAIN, "unitsLibrary");
    }

    stored = stored || {};

    this.unknownOpened = {};
    this.unknownFresh = {};
    var opened = stored.opened || {};
    var fresh = stored.fresh || {};

    Object.keys(opened).forEach(function (code) {
        if (Families[code] && !this.isHidden({ code: code })) {
            return;
        }
        if (!Families[code] && !UnitSyncer.DELETED_CODES[code]) {
            this.unknownOpened[code] = opened[code];
            if (fresh[code] !== undefined) {
                this.unknownFresh[code] = fresh[code];
            }
        }
        delete opened[code];
        delete fresh[code];
    }, this);

    fresh = cleverapps.listFromBit(fresh);
    opened = cleverapps.listFromBit(opened);

    for (var code in fresh) {
        Object.keys(fresh[code]).forEach(function (stage) {
            if (this.isHiddenFresh({ code: code, stage: stage })) {
                delete fresh[code][stage];
            }
        }, this);
    }

    this.opened = opened;
    this.fresh = fresh;

    Families.codes.forEach(function (code) {
        if (!this.opened[code]) {
            delete this.opened[code];
            delete this.fresh[code];
        }

        if (!this.fresh[code]) {
            delete this.fresh[code];
        }
    }.bind(this));

    if (needSave) {
        this.save();
    }
};

UnitsLibrary.prototype.getInfo = function () {
    return {
        opened: Object.assign({}, cleverapps.listToBit(this.opened), this.unknownOpened),
        fresh: Object.assign({}, cleverapps.listToBit(this.fresh), this.unknownFresh)
    };
};

UnitsLibrary.prototype.updateInfo = function (serverData) {
    this.load(serverData || {});
    this.save(true);

    cleverapps.offerManager.refreshAll();
};

UnitsLibrary.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.UNITS_LIBRARY + this.slot, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("unitslibrary" + this.slot);
    }
};

UnitsLibrary.prototype.reset = function () {
    this.opened = {};
    this.fresh = {};
    this.unknownOpened = {};
    this.unknownFresh = {};

    this.load(this.getInfo());
    this.save();
};

UnitsLibrary.prototype.resetFamilies = function (codes) {
    codes = cleverapps.toArray(codes);

    codes.forEach(function (code) {
        delete this.opened[code];
        delete this.fresh[code];
    }, this);

    this.load(this.getInfo());
    this.save();
};

UnitsLibrary.prototype.resetUnit = function (unit) {
    if (this.opened[unit.code]) {
        delete this.opened[unit.code][unit.stage];
    }

    if (this.fresh[unit.code]) {
        delete this.fresh[unit.code][unit.stage];
    }

    this.load(this.getInfo());
    this.save();
};

UnitsLibrary.LastStage = function (code) {
    var units = Families[code].units;
    for (var id = units.length - 1; id >= 0; id--) {
        if (!units[id].temporary && !units[id].deleted) {
            return {
                code: code,
                stage: id
            };
        }
    }
};

UnitsLibrary.ListByCode = function (code) {
    var units = Families[code].units;
    var res = [];
    for (var id = 0; id < units.length; id++) {
        if (!units[id].temporary && !units[id].deleted) {
            res.push({
                code: code,
                stage: id
            });
        }
    }
    return res;
};

UnitsLibrary.prototype.listOpenedPlusOneWithUnknown = function (code) {
    var units = UnitsLibrary.ListByCode(code);

    if (["advsource", "advprize"].indexOf(Families[code].type) !== -1) {
        units = units.filter(function (unit) {
            return this.isOpened(unit) || Map2d.currentMap.fogs.isPresentOnMap(unit);
        }.bind(this));
    }

    for (var i = units.length - 1; i >= 1; i--) {
        if (this.isOpened(units[i]) || this.isOpened(units[i - 1])) {
            break;
        }

        units[i].unknown = true;
    }
    return units;
};

UnitsLibrary.prototype.isOpened = function (unit) {
    return this.opened[unit.code] && this.opened[unit.code][unit.stage];
};

UnitsLibrary.prototype.isFresh = function (unit) {
    return this.fresh[unit.code] && this.fresh[unit.code][unit.stage] !== undefined;
};

UnitsLibrary.prototype.openUnit = function (unit) {
    if (!this.isOpened(unit) && !this.isHidden(unit)) {
        if (!this.opened[unit.code]) {
            this.opened[unit.code] = {};
        }

        this.opened[unit.code][unit.stage] = true;

        if (!this.isHiddenFresh(unit)) {
            if (!this.fresh[unit.code]) {
                this.fresh[unit.code] = {};
            }

            this.fresh[unit.code][unit.stage] = true;
        }

        cleverapps.eventBus.trigger("unitAvailable", unit);
        cleverapps.eventBus.trigger("unitStatusChanged", unit);

        cleverapps.toolbar.resetByType(ToolbarItem.UNITS_LIBRARY);
        cleverapps.offerManager.refreshByHero(unit);

        this.save();

        return true;
    }
};

UnitsLibrary.prototype.isHidden = function (unit) {
    return UnitsLibrary.HIDDEN_CODES.indexOf(unit.code) !== -1
        || UnitsLibrary.HIDDEN_TYPES.indexOf(Families[unit.code].type) !== -1;
};

UnitsLibrary.prototype.isHiddenFresh = function (unit) {
    var family = Families[unit.code];
    var data = family.units[unit.stage];

    if (data === undefined) {
        console.log("UNIT", unit.code, unit.stage);
        return false;
    }

    return this.isHidden(unit)
        || UnitsLibrary.HIDDEN_FRESH_CODES.indexOf(unit.code) !== -1 || data.temporary || data.deleted
        || UnitsLibrary.HIDDEN_FRESH_TYPES.indexOf(family.type) !== -1;
};

UnitsLibrary.prototype.removeFresh = function (unit, source) {
    if (this.fresh[unit.code][unit.stage]) {
        delete this.fresh[unit.code][unit.stage];

        Game.currentGame.addReward("soft", this.calcRewardCoins(unit), source, {
            withoutDelta: true,
            event: cleverapps.EVENTS.EARN.OTHER
        });

        cleverapps.eventBus.trigger("unitStatusChanged", unit);

        cleverapps.toolbar.resetByType(ToolbarItem.UNITS_LIBRARY);

        this.save();
    }
};

UnitsLibrary.prototype.hasOpened = function (code) {
    return Object.keys(this.opened[code] || {}).length > 0;
};

UnitsLibrary.prototype.hasFresh = function (code) {
    return Object.keys(this.fresh[code] || {}).length > 0;
};

UnitsLibrary.prototype.listAvailableByType = function (type) {
    var codes = this.listCodesByType(type).filter(function (code) {
        return this.hasOpened(code);
    }, this);

    if (codes.length === 0) {
        var current = this.getCurrentOfType(type);
        if (current) {
            return [current];
        }
    }

    return codes;
};

UnitsLibrary.prototype.listOpenedOfType = function (type) {
    return this.listCodesByType(type).filter(function (code) {
        return this.hasOpened(code);
    }, this);
};

UnitsLibrary.prototype.getCurrentOfType = function (type) {
    switch (type) {
        case "resource": case "xmresource": case "drresource": case "searesource": case "rpresource": case "earesource": case "chresource":
            return this.getCurrentResource(type);
        case "hero": case "xmhero": case "drhero": case "seahero": case "rphero": case "eahero":
            return this.getCurrentHero(type);
        case "fruit":
            return this.getCurrentFruit();
        case "source":
            return this.getCurrentResource() + "source";
        case "chest":
            return this.getCurrentResource() + "chest";
        case "seasonitem":
            if (Season.isRunning()) {
                return Season.getCurrentItemCode();
            }
            return undefined;
    }
};

UnitsLibrary.prototype.listCodesByType = function (types, expedition) {
    if (Array.isArray(types)) {
        return types.reduce(function (res, type) {
            return res.concat(this.listCodesByType(type));
        }.bind(this), []);
    }
    var codesByType = (UnitsLibrary.codesByType[types] || []).slice();

    var families = expedition ? Families.listExpeditionCodes(expedition) : Game.currentGame && Game.currentGame.level.families;
    if (families) {
        codesByType = codesByType.filter(function (code) {
            return families[code];
        });
    }

    return codesByType;
};

UnitsLibrary.prototype.listOpened = function (code) {
    return UnitsLibrary.ListByCode(code).filter(this.isOpened.bind(this));
};

UnitsLibrary.prototype.getFamilyName = function (code) {
    return Messages.get("Units." + code + ".name") || code + " family";
};

UnitsLibrary.prototype.getUnitName = function (unit, noSkin) {
    var key = "Units." + unit.code + ".stage" + (unit.stage + 1) + ".name";
    var fallbackName = unit.code + " stage " + (unit.stage + 1);

    if (noSkin) {
        return Messages.getNotSkinned(key) || fallbackName;
    }

    return Messages.get(key) || fallbackName;
};

UnitsLibrary.prototype.hasAnyFresh = function (codes) {
    return codes.some(this.hasFresh.bind(this));
};

UnitsLibrary.prototype.calcRewardCoins = function (unit) {
    return Math.round(3 * Math.pow(1.3, unit.stage));
};

UnitsLibrary.prototype.getLastOpenStage = function (code) {
    var units = Families[code].units;

    for (var stage = units.length - 1; stage >= 0; stage--) {
        if (this.isOpened({ code: code, stage: stage })) {
            return stage;
        }
    }

    return undefined;
};

UnitsLibrary.prototype.getLastOpenCode = function (type) {
    var codesByType = this.listCodesByType(type);
    for (var i = codesByType.length - 1; i >= 0; i--) {
        var code = codesByType[i];

        if (this.hasOpened(code)) {
            return code;
        }
    }

    return codesByType[0];
};

UnitsLibrary.prototype.getCurrentResource = function (type) {
    return this.getLastOpenCode(type || "resource");
};

UnitsLibrary.prototype.getCurrentCastleResource = function (type) {
    type = type || "resource";
    var codesByType = this.listCodesByType(type);
    for (var i = codesByType.length - 1; i >= 0; i--) {
        var code = codesByType[i];

        if (this.hasOpened(code) && !this.isOpened({ code: code, stage: Families[code].units.length - 1 })) {
            return code;
        }
    }

    return this.getCurrentResource(type);
};

UnitsLibrary.prototype.getCurrentFruit = function () {
    return this.getLastOpenCode("fruit");
};

UnitsLibrary.prototype.listUnopenedHeroes = function (type) {
    type = type || "hero";

    var page = cleverapps.travelBook.getCurrentPage();
    var codesByType = this.listCodesByType(type);
    var heroes = [];

    for (var i = 0; i < codesByType.length; i++) {
        var code = codesByType[i];

        if (!this.isHeroAvailable(code)) {
            if (Unit.GetDropType(code) === FamiliesHelper.Drop.Open) {
                if (this.getLastOpenStage(code) !== undefined) {
                    heroes.push(code);
                }
            } else if (!heroes.length || page.parallelHeroDrop) {
                heroes.push(code);
            }
        }
    }

    return heroes;
};

UnitsLibrary.prototype.getCurrentHero = function (type) {
    var heroes = this.listUnopenedHeroes(type);

    return heroes[0];
};

UnitsLibrary.prototype.getActiveHero = function (type) {
    type = type || "hero";
    var codesByType = this.listCodesByType(type);

    for (var i = codesByType.length - 1; i >= 0; i--) {
        var code = codesByType[i];
        if (this.isHeroAvailable(code)) {
            return code;
        }
    }

    return undefined;
};

UnitsLibrary.prototype.isHeroAvailable = function (code) {
    return this.heroes.some(function (unit) {
        return unit.code === code;
    });
};

UnitsLibrary.prototype.listAvailableHeroes = function () {
    return this.heroes;
};

UnitsLibrary.prototype.addHero = function (unit) {
    var index = this.heroes.indexOf(unit);
    if (index === -1) {
        this.heroes.push(unit);
    }
};

UnitsLibrary.prototype.removeHero = function (unit) {
    var index = this.heroes.indexOf(unit);
    if (index !== -1) {
        this.heroes.splice(index, 1);
    }
};

UnitsLibrary.prototype.findFreshUnit = function (units) {
    for (var i = 0; i < units.length; ++i) {
        var unit = units[i];
        if (unit.code && (unit.stage === undefined && this.hasFresh(unit.code) || this.isFresh(unit))) {
            return unit;
        }
    }
};

UnitsLibrary.prototype.findLastOpenedUnit = function (units) {
    for (var i = units.length - 1; i >= 0; --i) {
        var unit = units[i];
        if (unit.code && this.isOpened(unit)) {
            return unit;
        }
    }
};

UnitsLibrary.prototype.listTabCodes = function (expedition, tabId) {
    var codesById = cleverapps.clone(UnitsLibrary.codesByTabId[expedition] || UnitsLibrary.codesByTabId.default, true);

    if (expedition === "main" && Families.telly && cleverapps.rewardedAdsManager.isEnabled() || this.listCodesByType("telly", expedition).some(this.hasOpened, this)) {
        codesById[5].push("telly");
    }

    if (expedition === "main" && Families.cinema && cleverapps.rewardedAdsManager.isEnabled() || this.listCodesByType("cinema", expedition).some(this.hasOpened, this)) {
        codesById[5].push("cinema");
    }

    if (["dragonia", "dragonia2"].includes(expedition)) {
        codesById[5].push.apply(codesById[5], this.listCodesByType("drgift", expedition));
        codesById[1] = codesById[1].filter(function (code) {
            return !["dr2wheat", "dr2coal", "dr2barrel"].includes(code);
        });
    }
    if (["undersea", "undersea2", "undersea3"].includes(expedition)) {
        codesById[5].push.apply(codesById[5], this.listCodesByType("seagift", expedition));
    }

    if (expedition === "main" && cleverapps.eventManager.isActive("thanksgiving")) {
        codesById[5].push("thanksgiving");
    }

    return codesById[tabId] || codesById.reduce(function (totalCodes, tabCodes) {
        return totalCodes.concat(tabCodes);
    }, []);
};

UnitsLibrary.prototype.getExpeditionUnitType = function (type) {
    var expeditionType = UnitsLibrary.EXPEDITION_PREFIXES[cleverapps.travelBook.getCurrentPage().id] + type;
    return Families.types[expeditionType] ? expeditionType : type;
};

UnitsLibrary.Init = function () {
    this.codesByType = {};

    Families.codes.forEach(function (code) {
        var type = Families[code].type;

        if (!this.codesByType[type]) {
            this.codesByType[type] = [];
        }

        this.codesByType[type].push(code);
    }, this);

    this.codesByTabId = {};
    this.codesByTabId.default = [
        ["hero"],
        ["resource"],
        ["fruit"],
        ["source"],
        ["chest", "coinscup", "energycup", "caravanbox"],
        ["valuables", "custom", "magicplant", "energytree", "coinstree"]
    ];

    this.codesByTabId.xmas = [
        ["xmhero"],
        ["xmresource"],
        [],
        ["xmgrowing", "xmsource"],
        ["xmchest"],
        ["xmmagicplant"]
    ];

    this.codesByTabId.dragonia = this.codesByTabId.dragonia2 = this.codesByTabId.dragonia3 = this.codesByTabId.halloween = [
        ["drhero", "drrubyhero"],
        ["drresource"],
        [],
        ["drgrowing", "drsource"],
        ["drchest"],
        ["drmagicplant", "snailfeast", "snailhouse"]
    ];

    this.codesByTabId.undersea = this.codesByTabId.undersea2 = this.codesByTabId.undersea3 = [
        ["seahero", "searubyhero"],
        ["searesource"],
        ["seafruit"],
        ["seagrowing", "seasource"],
        ["seachest"],
        ["seamagicplant", "snailfeast", "snailhouse"]
    ];

    this.codesByTabId.rapunzel = this.codesByTabId.rapunzel2 = this.codesByTabId.rapunzel3 = [
        ["rphero"],
        ["rpresource", "rporder"],
        [],
        ["rpsource"],
        ["rpchest"],
        []
    ];

    this.codesByTabId.easter = [
        ["eahero"],
        ["earesource", "eaorder"],
        [],
        ["easource"],
        ["eachest"],
        []
    ];

    this.codesByTabId.china = [
        ["chhero"],
        ["chresource", "chorder"],
        [],
        ["chsource"],
        ["chchest"],
        []
    ];

    this.codesByTabId.collections = [
        ["clpet"],
        ["clpetrare"],
        ["clpetlegend"],
        [],
        ["clfruit"],
        [],
        ["clchest"],
        ["valuables"]
    ];

    this.codesByTabId.adventure = this.codesByTabId.adventure2 = [
        [],
        [],
        [],
        ["advsource"],
        ["advprize"],
        [],
        [],
        ["advdynamite"]
    ];

    Object.keys(this.codesByTabId).forEach(function (world) {
        this.codesByTabId[world].push(world === "default" ? ["landmark"] : []);
    }, this);

    Object.keys(this.codesByTabId).forEach(function (world) {
        var families = Families.listExpeditionCodes(world === "default" ? "main" : world);

        this.codesByTabId[world] = this.codesByTabId[world].map(function (types) {
            types = cleverapps.substract(types, UnitsLibrary.HIDDEN_TYPES);
            types = cleverapps.substract(types, UnitsLibrary.HIDDEN_FRESH_TYPES);

            var codes = types.reduce(function (total, type) {
                if (families[type]) {
                    return total.concat(type);
                }

                if (this.codesByType[type]) {
                    return total.concat(this.codesByType[type].filter(function (code) {
                        return families[code];
                    }));
                }

                return total;
            }.bind(this), []);
            codes = cleverapps.substract(codes, UnitsLibrary.HIDDEN_CODES);
            codes = cleverapps.substract(codes, UnitsLibrary.HIDDEN_FRESH_CODES);
            return codes;
        }, this);
    }, this);

    cleverapps.unitsLibraryBySlots = {};
    CustomSyncers.SLOTS.forEach(function (slot) {
        cleverapps.unitsLibraryBySlots[slot] = new UnitsLibrary(slot);
    });
};

UnitsLibrary.Switch = function (episodeNo, levelNo) {
    cleverapps.unitsLibrary = UnitsLibrary.GetInstance(episodeNo, levelNo);
};

UnitsLibrary.GetInstance = function (episodeNo, levelNo) {
    // for backward compatibility
    if (["mergecraft", "wondermerge", "fairy"].indexOf(cleverapps.config.name) !== -1 && ["collections", "dragonia", "dragonia2", "dragonia3", "undersea", "undersea2", "rapunzel", "rapunzel2", "rapunzel3"].indexOf(episodeNo) !== -1) {
        episodeNo = "main";
    }
    if (["mergecraft"].indexOf(cleverapps.config.name) !== -1 && ["undersea3"].indexOf(episodeNo) !== -1) {
        episodeNo = "main";
    }

    if (episodeNo === "main") {
        episodeNo = 0;
    }

    var slot = cleverapps.GameSaver.getStoreSlot(episodeNo, levelNo);
    return UnitsLibrary.GetInstanceBySlot(slot);
};

UnitsLibrary.GetInstanceBySlot = function (slot) {
    if (!cleverapps.unitsLibraryBySlots[slot]) {
        cleverapps.unitsLibraryBySlots[slot] = new UnitsLibrary(slot);
    }

    return cleverapps.unitsLibraryBySlots[slot];
};

UnitsLibrary.EXPEDITION_PREFIXES = {
    collections: "cl",
    dragonia: "dr",
    dragonia2: "dr",
    dragonia3: "dr",
    halloween: "dr",
    undersea: "sea",
    undersea2: "sea",
    undersea3: "sea",
    rapunzel: "rp",
    rapunzel2: "rp",
    rapunzel3: "rp",
    xmas: "xm",
    easter: "ea",
    china: "ch"
};

UnitsLibrary.HIDDEN_CODES = ["multiCellBody", "thirdelement", "decorator", "debugunit"];
UnitsLibrary.HIDDEN_TYPES = ["battlepasspoints", "seasonitem"];

UnitsLibrary.HIDDEN_FRESH_CODES = ["dragonmine", "rubiesplantchest", "landmark", "cllandmark", "landmarkspot", "landmarkpreview"];
UnitsLibrary.HIDDEN_FRESH_TYPES = ["generator", "unitsshop", "monster", "ruins"];

UnitsLibrary.OLD_EXPEDITION_INTERVAL = cleverapps.parseInterval("2 weeks");

if (typeof exports === "undefined") {
    CustomSyncers.registerBySlots("unitslibrary", function (slot) {
        return UnitsLibrary.GetInstanceBySlot(slot).getInfo();
    }, function (slot, serverData) {
        UnitsLibrary.GetInstanceBySlot(slot).updateInfo(serverData);
    });
}

if (typeof cc === "undefined") {
    module.exports = UnitsLibrary;
}
