/**
 * Created by evgeny on 11.06.2024
 */

FogsConfig.initialize = function () {
    FogsConfig.main = {
        fog0: {
            id: 0,
            headless: true
        },
        fog1: {
            id: 1,
            headless: true,
            parentFogId: "fog0",
            conditions: {
                family: "chloepetit"
            }
        }
    };

    FogsConfig.afterInitialize();
};

FogsConfig.initialize();
