/**
 * Created by evgenijsenkevic on 20.09.2024
 */

var SwitchableDecorators = function (unit, options, data) {
    UnitComponent.call(this, unit);

    this.unit = unit;
    this.region = Map2d.currentMap.regions[data.region];

    this.choices = data.choices;
    this.choice = this.showingChoice = options.choice || 0;

    this.setChoice(this.choice);
};

SwitchableDecorators.prototype = Object.create(UnitComponent.prototype);
SwitchableDecorators.prototype.constructor = SwitchableDecorators;

SwitchableDecorators.prototype.save = function (data) {
    data.choice = this.choice;
};

SwitchableDecorators.prototype.iterateDecoratorsWithCallback = function (iterator) {
    var positions = this.region.positions;

    var keys = {};
    positions.forEach(function (position) {
        keys[position.x] = true;
    });

    keys = Object.keys(keys).sort(function (a, b) {
        return (+a) - (+b);
    });

    var animateRow = function (x, f) {
        var decorators = positions.reduce(function (decorators, position) {
            if (position.x === x) {
                decorators = decorators.concat(Map2d.currentMap.decorators.getDecorators(position.x, position.y));
            }
            return decorators;
        }, []);

        f = cleverapps.wait(decorators.length, f);

        decorators.forEach(function (decorator) {
            iterator(decorator, f);
        });
    };

    var actions = keys.map(function (x) {
        return function (f) {
            return animateRow(+x, f);
        };
    });

    new ActionPlayer(actions).play();
};

SwitchableDecorators.prototype.iterateDecorators = function (iterator) {
    this.region.positions.forEach(function (position) {
        var decorators = Map2d.currentMap.decorators.getDecorators(position.x, position.y);
        if (decorators) {
            decorators.forEach(iterator);
        }
    });
};

SwitchableDecorators.prototype.getChoice = function () {
    return this.choice;
};

SwitchableDecorators.prototype.setChoice = function (choice, f) {
    f = f || function () {};
    this.iterateDecorators(function (decorator) {
        decorator.setAnimation("choice" + choice);
    });

    cleverapps.timeouts.setTimeout(f, 100);
    this.choice = choice;
    this.unit.save();
};

SwitchableDecorators.prototype.getLastStage = function () {
    return Families[this.unit.code].units.length - 1;
};

SwitchableDecorators.prototype.showChoice = function (choice) {
    if (this.showingChoice === choice) {
        return;
    }

    this.iterateDecoratorsWithCallback(function (decorator, f) {
        var view = decorator && decorator.getView && decorator.getView();
        if (typeof view === "undefined") {
            f();
            return;
        }

        view.setAnimation("switch" + choice);
        view.setCompleteListenerOnce(f);
    });

    this.showingChoice = choice;
};

SwitchableDecorators.prototype.animateHover = function () {
};

SwitchableDecorators.prototype.stopAnimateHover = function () {
};

SwitchableDecorators.prototype.handleClick = function () {
    cleverapps.meta.display({
        focus: "SwitchableClick",
        action: function (f) {
            new MakeChoiceWindow(this);
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
};