/**
 * Created by iamso on 15.11.2021
 */

var Ruins = function (unit, options) {
    UnitComponent.call(this, unit);

    this.appearTime = options.appearTime || Date.now();

    if (!cleverapps.environment.isEditorScene()) {
        this.activate();
    }

    if (options.appearTime === undefined) {
        cleverapps.audio.playSound(bundles.merge.urls.ruins_appear);
    }
};

Ruins.prototype = Object.create(UnitComponent.prototype);
Ruins.prototype.constructor = Ruins;

Ruins.prototype.onBlowUp = function (tierName) {
    if (this.view) {
        this.view.blowUp(tierName);
    }
};

Ruins.prototype.activate = function () {
    this.unit.setPrizes({ sprite: bundles.merge.frames.prize_exclamation });
    this.liveTimeout = new cleverapps.LongTimeout(this.perish.bind(this), this.getTimeLeft());
};

Ruins.prototype.getTimeLeft = function () {
    var timePassed = Date.now() - this.appearTime;
    return Math.max(0, Ruins.LIFETIME - timePassed);
};

Ruins.prototype.perish = function () {
    this.destructor();

    if (!this.ruinsWinOpen) {
        this.unit.onDestruction();
        this.unit.remove(true);
    }
};

Ruins.prototype.buy = function (tierName) {
    var tier = this.getRelevantTier(tierName);

    var rewardsAmount = tier.rewards.reduce(function (sum, unit) {
        return sum + unit.amount;
    }, 0);

    var slotsLacking = rewardsAmount - Map2d.currentMap.countEmptySlots();

    if (slotsLacking > 0) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: slotsLacking });
        return false;
    }

    if (tier.soft || tier.hard) {
        var result = tier.soft ? cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.RUIN, tier.soft, { source: this.unit })
            : cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.RUIN, tier.hard);
        if (result) {
            this.giveReward(tierName);
        }
    }

    return true;
};

Ruins.prototype.getRelevantTier = function (tierName) {
    var page = cleverapps.travelBook.getCurrentPage();
    var tier = (Ruins.TIERS[page.location] || Ruins.TIERS.default)[tierName];

    if (!page.withEnergy()) {
        tier = cleverapps.clone(tier, true);
        tier.rewards = tier.rewards.filter(function (unit) {
            return unit.code !== "energy";
        });
    }

    return tier;
};

Ruins.prototype.giveReward = function (tierName) {
    var units = [];

    this.getRelevantTier(tierName).rewards.forEach(function (data) {
        for (var i = 0; i < data.amount; ++i) {
            units.push({
                code: data.code,
                stage: data.stage
            });
        }
    });

    cleverapps.meta.distract({
        focus: "GiveRuinReward",
        actions: [
            function (f) {
                this.destructor();

                this.onBlowUp(tierName);
                this.unit.remove(true);

                var delay = cleverapps.skins.getSlot("ruinsRewardsDelay");
                if (!delay) {
                    delay = cleverapps.config.name === "wondermerge" ? 2800 : 2000;
                }

                Game.currentGame.counter.setTimeout(f, delay);
            }.bind(this),

            function (f) {
                Game.currentGame.spawn(units, this.unit, {});
                Game.currentGame.counter.setTimeout(f, 1400);
            }.bind(this)
        ]
    });
};

Ruins.prototype.save = function (data) {
    data.appearTime = this.appearTime;
};

Ruins.prototype.destructor = function () {
    clearTimeout(this.liveTimeout);
    delete this.liveTimeout;
};

Ruins.prototype.handlePrizes = function () {
    this.ruinsWinOpen = true;
    this.unit.squeeze();
    cleverapps.audio.playSound(bundles.main.urls.click_effect);

    cleverapps.meta.display({
        focus: "ruinsWindow",
        control: ["MenuBarGoldItem", "MenuBarCoinsItem"],
        actions: [
            function (f) {
                if (cleverapps.gameModes.skipOpenChestWindow) {
                    this.giveReward("costly");
                    f();
                } else {
                    new RuinsWindow(this);
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            }.bind(this),

            function (f) {
                delete this.ruinsWinOpen;

                if (!this.liveTimeout) {
                    this.perish();
                }

                f();
            }.bind(this)
        ]
    });

    return true;
};

Ruins.LIFETIME = cleverapps.parseInterval(cleverapps.config.debugMode ? "5 minute" : "1 hours");
Ruins.TIERS = {
    default: {
        cheap: {
            soft: 59,
            rewards: [
                { code: "sacks", stage: 0, amount: 1 },
                { code: "worker", stage: 0, amount: 1 },
                { code: "herochest", stage: 0, amount: 1 }
            ]
        },
        costly: {
            hard: 199,
            rewards: [
                { code: "sacks", stage: 2, amount: 3 },
                { code: "herochest", stage: 2, amount: 3 },
                { code: "worker", stage: 2, amount: 3 },
                { code: "crystal", stage: 3, amount: 1 }
            ]
        }
    }
};
