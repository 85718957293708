/**
 * Created by r4zi4l on 17.12.2021
 */

var ToyFactory = function (unit, options) {
    UnitComponent.call(this, unit);

    this.time = options.time || Date.now();

    this.initTimeout = setTimeout(this.init.bind(this), 0);
};

ToyFactory.prototype = Object.create(UnitComponent.prototype);
ToyFactory.prototype.constructor = ToyFactory;

ToyFactory.prototype.onStateChanged = function () {
    if (this.view) {
        this.view.restoreState();
    } 
};

ToyFactory.prototype.onStart = function () {
    if (this.view) {
        this.view.animateStart();
    }
};

ToyFactory.prototype.save = function (data) {
    data.time = this.time;
};

ToyFactory.prototype.destructor = function () {
    cleverapps.timeouts.clearInterval(this.checkSpaceInterval);
    clearTimeout(this.timeout);
    clearTimeout(this.initTimeout);
};

ToyFactory.prototype.getTimeLeft = function () {
    return Math.max(0, this.time + ToyFactory.PRODUCTION_DURATION - Date.now());
};

ToyFactory.prototype.getFreeCells = function () {
    return this.region.filter(function (cell) {
        return Map2d.currentMap.isEmpty(cell.x, cell.y);
    });
};

ToyFactory.prototype.init = function () {
    this.region = ToyFactory.findRegion(this.unit, "toyfactory");

    var count = 0;
    if (this.time < Date.now()) {
        count = Math.floor((Date.now() - this.time) / ToyFactory.PRODUCTION_DURATION);
        this.time += count * ToyFactory.PRODUCTION_DURATION;
    }

    var cells = this.getFreeCells();
    for (var i = 0; i < cells.length && count > 0; ++i, --count) {
        Game.currentGame.spawn(this.listPrize(), this.unit, {
            targetCell: cells[i],
            skipCheckScreen: true,
            skipCheckEqual: true
        });
    }

    if (count > 0) {
        this.stop();
        return;
    }

    this.start();
};

ToyFactory.prototype.start = function () {
    cleverapps.timeouts.clearInterval(this.checkSpaceInterval);
    clearTimeout(this.timeout);
    this.timeout = new cleverapps.LongTimeout(this.spawn.bind(this), this.getTimeLeft());
    this.unit.setPrizes();
    this.onStart();
};

ToyFactory.prototype.canSpawn = function () {
    return this.getFreeCells().length > 0;
};

ToyFactory.prototype.spawn = function () {
    if (!this.canSpawn()) {
        this.stop();
        return;
    }

    var id, x, y;
    var region = this.region.filter(function (cell) {
        var unit = Map2d.currentMap.getUnit(cell.x, cell.y);
        return !unit || !unit.isMultiCellBody() && !unit.isMultiCell();
    });
    for (id = 0; id < region.length; id++) {
        x = region[id].x;
        y = region[id].y;
        if (Map2d.currentMap.isEmpty(x, y)) {
            break;
        }

        var unit = Map2d.currentMap.getUnit(x, y);
        if (!unit) {
            cleverapps.throwAsync("ToyFactory.spawn map incorrect state - " + JSON.stringify({
                x: x,
                y: y,
                isEmpty: !!Map2d.currentMap.isEmpty(x, y),
                unit: !!Map2d.currentMap.getUnit(x, y),
                isGround: !!Map2d.currentMap.isGround(x, y),
                getFog: !!Map2d.currentMap.getFog(x, y)
            }));
        }
    }

    while (id > 0) {
        var occupiedUnit = Map2d.currentMap.getUnit(region[id - 1].x, region[id - 1].y);
        occupiedUnit && occupiedUnit.move(region[id].x, region[id].y);
        id--;
    }

    Game.currentGame.spawn(this.listPrize(), this.unit, {
        targetCell: region[0],
        skipCheckScreen: true,
        skipCheckEqual: true,
        noSound: true
    });
    this.time = Date.now();
    this.start();
};

ToyFactory.prototype.stop = function () {
    this.unit.setPrizes({
        sprite: bundles.merge.frames.prize_exclamation
    });
    this.onStateChanged();

    cleverapps.timeouts.clearInterval(this.checkSpaceInterval);
    this.checkSpaceInterval = cleverapps.timeouts.setInterval(function () {
        if (this.canSpawn()) {
            this.spawn();
        }
    }.bind(this), ToyFactory.CHECK_REGION_INTERVAL);
};

ToyFactory.prototype.handlePrizes = function () {
    if (!this.canSpawn()) {
        cleverapps.centerHint.createTextHint("Toyfactory.nospace");
    }
    return true;
};

ToyFactory.prototype.isTutor = function () {
    return Game.currentGame.tutorial.isActive() && Game.currentGame.tutorial.step.type === Map2d.FACTORY_SPEEDUP;
};

ToyFactory.prototype.offerSpeedup = function () {
    if (this.getTimeLeft() > 0) {
        cleverapps.meta.display({
            stack: this.isTutor(),
            focus: "ConfirmSpeedUpWindowFactory",
            action: function (f) {
                new ConfirmSpeedUpWindow({
                    timeLeft: this.getTimeLeft(),
                    totalDuration: ToyFactory.PRODUCTION_DURATION,
                    eventName: cleverapps.EVENTS.SPENT.FACTORY,
                    price: this.isTutor() ? 0 : ToyFactory.SPEEDUP_PRICE,
                    canCoins: false,
                    callback: function () {
                        Map2d.mapEvent(Map2d.FACTORY_SPEEDUP, { affected: this.unit });

                        clearTimeout(this.timeout);
                        this.time = Math.max(0, this.time - ToyFactory.PRODUCTION_DURATION);
                        this.spawn();
                    }.bind(this)
                });
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        });
        return true;
    }
};

ToyFactory.prototype.listPrize = function () {
    if (["xmas"].includes(cleverapps.travelBook.getCurrentPage().id)) {
        var codes = cleverapps.unitsLibrary.listOpenedOfType(cleverapps.unitsLibrary.getExpeditionUnitType("product0"));
        if (codes.length === 0) {
            codes = cleverapps.unitsLibrary.listCodesByType(cleverapps.unitsLibrary.getExpeditionUnitType("product0"));
        }
    } else {
        codes = cleverapps.unitsLibrary.listCodesByType(cleverapps.unitsLibrary.getExpeditionUnitType("gift"));
    }

    return {
        code: cleverapps.Random.choose(codes, Date.now()),
        stage: 0
    };
};

ToyFactory.PRODUCTION_DURATION = cleverapps.parseInterval("5 minutes");
ToyFactory.CHECK_REGION_INTERVAL = cleverapps.parseInterval("3 seconds");
ToyFactory.SPEEDUP_PRICE = 49;

ToyFactory.findRegion = function (unit, region) {
    var occupied = {};

    unit.getShape().forEach(function (part) {
        occupied[(unit.x + part.x) + "_" + (unit.y + part.y)] = true;
    });

    for (var name in Map2d.currentMap.regions) {
        if (name.indexOf(region) !== 0) {
            continue;
        }

        var originalPositions = Map2d.currentMap.regions[name].positions;
        var positions = originalPositions.filter(function (position) {
            return !occupied[position.x + "_" + position.y];
        });
        if (originalPositions.length === positions.length) {
            continue;
        }

        positions.sort(function (a, b) {
            return cc.pDistanceSQ(unit, a) - cc.pDistanceSQ(unit, b);
        });

        var cell = positions[0];
        var reverse = positions.find(function (position) {
            return (cell.x === position.x - 1) && (cell.y === position.y);
        }) || occupied[(cell.x - 1) + "_" + cell.y] ? 1 : 0;

        positions.sort(function (a, b) {
            return a.y - b.y || ((a.y - cell.y + reverse) % 2 ? a.x - b.x : b.x - a.x);
        });

        var index = positions.indexOf(cell);
        positions = [].concat(positions.slice(index), positions.slice(0, index).reverse());

        return positions;
    }
    return [];
};
