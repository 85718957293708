/**
 * Created by Andrey Popov on 5/22/23.
 */

var MakeChoiceWindowItem = cc.Scale9Sprite.extend({
    ctor: function (options) {
        this._super(bundles.make_choice_window.frames.item_bg_png);
        this.options = options;
        this.unitCode = this.options.switchable.unit.code;
        this.switchable = this.options.switchable;

        var styles = cleverapps.styles.MakeChoiceWindowItem;
        this.setContentSize2(styles.width, styles.height);

        var unitView = this.createChoiceView(options.choice);
        unitView.setPosition(this.width / 2, this.height / 2);
        this.addChild(unitView);

        this.selectLight = new cleverapps.Spine(bundles.make_choice_window.jsons.icon_light_json);
        this.selectLight.setPosition(this.width / 2, this.height / 2);
        this.addChild(this.selectLight);

        if (!options.isPreviousChoice && (options.params.ads || options.params.price)) {
            this.createBadge(options.params);
        }

        cleverapps.UI.applyHover(this);
        cleverapps.UI.onClick(this, this.onClick.bind(this));
    },

    onClick: function (event) {
        this.options.onClick();
        this.selectLight.setPosition(this.convertToNodeSpace(event.getLocation()));
        this.selectLight.setAnimation(0, "animation", false);
    },

    select: function () {
        this.setSpriteFrame(bundles.make_choice_window.frames.item_bg_on_png);
    },

    unSelect: function () {
        this.setSpriteFrame(bundles.make_choice_window.frames.item_bg_png);
    },

    createBadge: function (params) {
        var styles = cleverapps.styles.MakeChoiceWindowItem.badge;

        var badge = new cc.Sprite(bundles.make_choice_window.frames.item_badge_png);

        var text;
        if (params.ad) { 
            this.ad = true;
            text = new cc.Sprite(bundles.make_choice_window.frames.item_ads_png);
        } else if (params.price) {
            this.price = params.price;
            text = new TextWithIcon("$$" + params.price, {
                font: cleverapps.styles.FONTS.BUTTON_BOOSTER,
                icons: {
                    "##": bundles.make_choice_window.frames.item_ads_png
                }
            });
        }

        badge.addChild(text);
        text.setPositionRound(styles.text);
        text.setRotation(styles.text.rotation);

        this.addChild(badge);
        badge.setPositionRound(styles);
    },

    createChoiceView: function (choice) {
        var styles = cleverapps.styles.MakeChoiceWindowItem.unitView;
        var view = new cleverapps.Spine(bundles["unit_" + this.unitCode].jsons["unit_" + this.unitCode + "_" + (this.switchable.getLastStage() + 1)]);

        cleverapps.UI.fitToBox(view, styles);
        view.setAnimation(0, "choice" + choice, true);

        return view;
    }
});

cleverapps.styles.MakeChoiceWindowItem = {
    badge: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: -20 },
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            width: 200,
            rotation: -15
        }
    },
    width: 250,
    height: 250,
    unitView: {
        width: 210,
        height: 210
    }
};
