/**
 * Created by mac on 12/23/20
 */

var Chest = function (unit) {
    UnitComponent.call(this, unit);
};

Chest.prototype = Object.create(UnitComponent.prototype);
Chest.prototype.constructor = Chest;

Chest.prototype.openAnimation = function () {
    if (this.view) {
        this.view.openChest();
    }
};

Chest.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Chest.prototype.open = function () {
    if (!this.checkFreeSpaceToOpen()) {
        return;
    }

    var prizes = this.listPrize();

    Map2d.mapEvent(Map2d.PRIZE_HARVESTED, { affected: this.unit, prizes: prizes });

    if (this.extraPrizes) {
        prizes = this.extraPrizes.concat(prizes);
    }

    var options = {
        radius: this.unit.getData().radius !== undefined ? this.unit.getData().radius : Map2d.RADIUS_BIG,
        skipCheckScreen: this.unit.getData().skipCheckScreen
    };

    if (cleverapps.gameModes.fastSpawnPrizes) {
        options.wysiwygExplosionChest = true;
        options.skipCheckScreen = true;
    }

    this.openAnimation();
    this.unit.remove(true);

    Game.currentGame.spawn(prizes, this.unit, options);
};

Chest.prototype.checkFreeSpaceToOpen = function () {
    var merge = Game.currentGame;
    var prizes = this.listPrize();
    var left = prizes.length + (this.extraPrizes ? this.extraPrizes.length : 0) - merge.map.countEmptySlots() - 1;
    if (left <= 0) {
        return true;
    }
    var mergeblUnit = merge.map.hasMergeable();
    if (mergeblUnit) {
        this.hintToFreeSpace(mergeblUnit, left);
        return false;
    }
    var units = merge.map.listAvailableUnits();
    for (var i = 0; i < units.length; i++) {
        var unit = units[i];
        var info = unit.getActionInfo();
        var collectible = unit.findComponent(Collectible);
        var canBeSold = info && info.buttons && info.buttons.sell;
        if (collectible || canBeSold) {
            this.hintToFreeSpace(unit, left);
            return false;
        }
    }

    cleverapps.meta.distract({
        focus: "NotEnoughSpaceWindow",
        action: function (f) {
            new NotEnoughSpaceWindow(this.unit);
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
    return false;
};

Chest.prototype.hintToFreeSpace = function (unit, left) {
    var merge = Game.currentGame;
    merge.map.getView().scroll.runAction(
        new cc.Sequence(
            new cc.CellScrollAction(unit, {
                allowScrollWithFocus: true
            }).easing(cc.easeInOut(2)),
            new cc.CallFunc(
                function () {
                    cleverapps.centerHint.createTextHint("Spawn.nospace.try_to_free", { left: left });
                    cleverapps.scenes.getRunningScene().addTarget(unit.x, unit.y);
                }
            )
        )
    );
};

Chest.prototype.calcSellPrice = function () {
    var result = 0;
    this.listPrize().forEach(function (unit) {
        var price;
        if (unit.code === "coins") {
            price = Families[unit.code].units[unit.stage].value * 0.5;
        } else if (unit.code === "rubies") {
            price = Families[unit.code].units[unit.stage].value * 1.7;
        } else {
            price = Unit.CalcSellPrice(unit);
        }
        result += price;
    });
    return Math.ceil(result);
};

Chest.ListPrizeProb = function (chest) {
    var prizes = Chest.ListPrizes(chest);
    var res = {}, code;

    // eslint-disable-next-line no-shadow
    var addTypes = function (code, stage, family, hero, prob, basic) {
        var codes = [code];
        var openPenalty = 1;

        if (family) {
            codes = cleverapps.unitsLibrary.listAvailableByType(code);
            openPenalty = family.openPenalty !== undefined ? family.openPenalty : 1;
            if (!codes.length) {
                return false;
            }
        }
        if (hero) {
            codes = cleverapps.unitsLibrary.listUnopenedHeroes(hero);

            codes = codes.filter(function (heroCode) {
                return heroCode && stage < Families[heroCode].units.length - 1;
            });

            if (codes.length === 0) {
                return false;
            }
        }

        var probByCode = cleverapps.normalize(codes.map(function (code) {
            if (!Families[code]) {
                cleverapps.throwAsync("ListPrizeProb wrong code - " + code);
                return 1;
            }

            if (Families[code].probability !== undefined) {
                return Families[code].probability;
            }

            var isComplete = cleverapps.unitsLibrary.getLastOpenStage(code) === Families[code].units.length - 1;
            return isComplete ? openPenalty : 1;
        }));

        for (var i = 0; i < codes.length; i++) {
            res[code].types.push({
                code: codes[i],
                stage: Unit.RepairDeletedStages(codes[i], stage),
                prob: probByCode[i] * prob,
                basic: probByCode[i] * basic
            });
        }

        return true;
    };

    var basicTotal = 0;
    for (code in prizes) {
        var stage, amountStages = 0;

        res[code] = {
            minAmount: 0,
            maxAmount: 0,
            resource: prizes[code].resource,
            types: []
        };

        for (stage = 0; stage < prizes[code].basic.length; stage++) {
            if (prizes[code].basic[stage] >= 0) {
                amountStages++;
            }
        }
        for (stage = 0; stage < prizes[code].basic.length; stage++) {
            if (prizes[code].basic[stage] >= 0) {
                res[code].minAmount += prizes[code].basic[stage];
                res[code].maxAmount += prizes[code].basic[stage];
                if (addTypes(code, stage, prizes[code].family, prizes[code].hero, prizes[code].itemsProb ? prizes[code].itemsProb[stage] : 1 / amountStages, prizes[code].basic[stage])) {
                    basicTotal += prizes[code].basic[stage];
                }
            }
        }
        if (prizes[code].bonus) {
            if (!prizes[code].prob) {
                res[code].minAmount += prizes[code].bonus[0];
            }
            res[code].maxAmount += prizes[code].bonus[1];
        }

        if (!res[code].types.length || !res[code].maxAmount) {
            delete res[code];
        }
    }

    var total = {
        minAmount: 0,
        maxAmount: 0,
        types: []
    };

    for (code in res) {
        total.minAmount += res[code].minAmount;
        total.maxAmount += res[code].maxAmount;
    }

    var med = (total.minAmount + total.maxAmount) / 2;
    var i;

    var notBasicProb = 1 - basicTotal / med;

    for (code in res) {
        var cur = (res[code].minAmount + res[code].maxAmount) / 2;
        for (i = 0; i < res[code].types.length; i++) {
            var type = res[code].types[i];
            total.types.push(type);
            type.prob = (type.prob * cur / med) * notBasicProb + (basicTotal ? (type.basic / basicTotal) * (1 - notBasicProb) : 0);
        }
    }

    var probSum = 0;
    for (i = 0; i < total.types.length - 1; i++) {
        if (probSum + total.types[i].prob >= 1) {
            total.types = total.types.slice(0, i + 1);
            break;
        }
        probSum += total.types[i].prob;
    }
    total.types[total.types.length - 1].prob = 1 - probSum;

    for (i = 0; i < total.types.length; i++) {
        total.types[i].prob = Math.round(total.types[i].prob * 10000) / 100;
    }

    var order = [];
    for (i = 0; i < total.types.length; i++) {
        order.push(i);
    }

    order = order.sort(function (a, b) {
        if (total.types[b].prob !== total.types[a].prob) {
            return total.types[b].prob - total.types[a].prob;
        }
        return a - b;
    });

    res = {
        minAmount: total.minAmount,
        maxAmount: total.maxAmount,
        types: []
    };

    for (i = 0; i < order.length; i++) {
        res.types.push(total.types[order[i]]);
    }

    return res;
};

Chest.ListPrizes = function (chest) {
    var prizes = cleverapps.clone(ChestListPrize[chest.code][chest.stage], true);

    for (var key in prizes) {
        if (prizes[key].hero) {
            var heroCode = cleverapps.unitsLibrary.getCurrentHero(prizes[key].hero);
            if (!heroCode || (cleverapps.travelBook.getCurrentPage().id === "halloween" && Families[heroCode].units[0].landmarkDonor)) {
                Object.assign(prizes, prizes[key].noHeroes || {});
                delete prizes[key];
            }
        }
    }

    if (prizes.cinema) {
        if (!cleverapps.rewardedAdsManager.isEnabled() || cleverapps.user.level < 7 || Map2d.currentMap.listAvailableUnits({ code: "cinema", stage: 3 }).length) {
            delete prizes.cinema;
        }
    }

    if (chest.code === "seasonchest") {
        var resource = cleverapps.unitsLibrary.getCurrentResource();

        if (prizes.seasonitem) {
            if (Season.isRunning()) {
                prizes[Season.getCurrentItemCode()] = prizes.seasonitem;
            } else if (resource && !prizes[resource]) {
                prizes[resource] = prizes.seasonitem;
                prizes[resource].resource = true;
            } else if (!prizes.coins) {
                prizes.coins = prizes.seasonitem;
            }
            delete prizes.seasonitem;
        }
    }

    if (!cleverapps.travelBook.getCurrentPage().withEnergy()) {
        delete prizes.energy;
    }

    return prizes;
};

Chest.prototype.listPrize = function () {
    this.unit.seedRandom();
    var prizes = Chest.ListPrizes(this.unit);
    var res = [];

    var addPrize = function (code, stage, family, hero) {
        if (family) {
            var codes = cleverapps.unitsLibrary.listAvailableByType(code);
            if (!codes.length) {
                return;
            }

            var openPenalty = family.openPenalty !== undefined ? family.openPenalty : 1;
            var probs = cleverapps.normalize(codes.map(function (code) {
                if (Families[code].probability !== undefined) {
                    return Families[code].probability !== undefined;
                }
                var isComplete = cleverapps.unitsLibrary.getLastOpenStage(code) === Families[code].units.length - 1;
                return isComplete ? openPenalty : 1;
            }));

            code = codes[cleverapps.Random.pickOne(probs)];
        }
        if (hero) {
            var heroCodes = cleverapps.unitsLibrary.listUnopenedHeroes(hero).filter(function (code) {
                return Families[code].units.length > 1;
            });

            probs = cleverapps.normalize(heroCodes.map(function (code) {
                return Families[code].probability !== undefined ? Families[code].probability : 1;
            }));

            code = heroCodes[cleverapps.Random.pickOne(probs)];

            if (!code || stage >= Families[code].units.length - 1) {
                return;
            }

            if (Game.currentGame.isMainGame() && cleverapps.gameLevel.getLevel() < 3) {
                return;
            }
        }
        stage = Unit.RepairDeletedStages(code, stage);
        res.push(Prizes.decreasePrizeStageIfNeeded({
            code: code,
            stage: stage
        }));
    };

    for (var code in prizes) {
        var prize = prizes[code];
        var types = [], i, stage, amountStages = 0;
        for (stage = 0; stage < prize.basic.length; stage++) {
            if (prize.basic[stage] >= 0) {
                amountStages++;
            }
        }
        for (stage = 0; stage < prize.basic.length; stage++) {
            if (prize.basic[stage] >= 0) {
                for (i = 0; i < prize.basic[stage]; i++) {
                    addPrize(code, stage, prize.family, prize.hero);
                }
                types.push({
                    code: code,
                    stage: stage,
                    family: prize.family,
                    hero: prize.hero,
                    prob: prize.itemsProb ? prize.itemsProb[stage] : 1 / amountStages
                });
            }
        }
        if (prize.bonus) {
            var amount = cleverapps.Random.random(prize.bonus[0], prize.bonus[1]);
            for (i = 0; i < amount; i++) {
                if (prize.prob) {
                    if (cleverapps.Random.nextDouble() > prize.prob) {
                        continue;
                    }
                }
                var p = cleverapps.Random.nextDouble();
                var sum = 0;
                for (var v = 0; v < types.length; v++) {
                    sum += types[v].prob;
                    if (sum >= p) {
                        addPrize(types[v].code, types[v].stage, types[v].family, types[v].hero);
                        break;
                    }
                }
            }
        }
    }

    return res;
};

Chest.prototype.handleClick = function () {
    this.unit.squeeze();
    cleverapps.audio.playSound(bundles.menubar.urls.coins_effect);

    if (cleverapps.gameModes.skipOpenChestWindow) {
        this.open();
        return true;
    }

    cleverapps.meta.display({
        stack: Game.currentGame.tutorial.checkTargets(this.unit) && Game.currentGame.tutorial.checkTypes([Map2d.OPENCHEST, Map2d.CLICK_UNIT, Map2d.SPAWN]),
        focus: "openChestWindow",
        actions: [
            function (f) {
                cleverapps.timeouts.setTimeout(f, 300);
            },

            function (f) {
                new OpenChestWindow(this);
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        ]
    });

    return true;
};

Chest.prototype.getEnergyAmount = function () {
    return this.unit.getData().energyPrize;
};

Chest.prototype.isEnergyAvailable = function () {
    if (this.unit.isLockedUnit()) {
        return false;
    }

    var mission = cleverapps.missionManager.findByType(Mission.TYPE_CHESTTIME);
    return (Chest.AlwaysWithEnergy(this.unit) || mission && mission.isRunning()) && this.getEnergyAmount();
};

Chest.AlwaysWithEnergy = function (unit) {
    return unit.code === "dailytaskchest";
};

Chest.prototype.setExtraPrizes = function (prizes) {
    if (prizes) {
        this.extraPrizes = cleverapps.toArray(prizes);
    } else {
        this.extraPrizes = undefined;
    }
};

Chest.prototype.lockStateUpdate = function () {
    this.onUpdateState();
};

Chest.classifyEnergy = function (amount) {
    if (amount > 15) {
        return 3;
    } if (amount > 5) {
        return 2;
    }
    return 1;
};
